/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { AxiosResponse } from 'axios';
import { AssessmentActions, initialAssessmentState, useAssessmentStore } from '@app/store/use.assessment.store';
import { addRequestStatus, removeRequestStatus } from '@app/store/global.actions';
import { useGlobalStore } from '@app/store/use.global.store';
import { AssessmentState, AssessmentStateInterface, IFilterData, SunburstChartDataType } from '@app/store/store.types';
import { ReviewService } from '@services/review.service';
import { UserAPI } from '@api/services/user-api.resources';
import { ReviewAPI, ReviewCatalogAPI, ReviewExportAPI, ReviewVoterAPI } from '@api/services/review-api.resources';
import { NotificationService } from '@services/notification.service';
import { FILE_TYPE, MESSAGE, initialFilterData, APP_ROLES } from '@lib/common.constants';
import { API_REQUEST_KEY } from '@lib/requests.constants';
import { AttachedDocument } from '@app/types/dimension.types';
import { SupportService } from '@services/support.service';
import { AssessmentStatement, CatalogDimension, IDimensionSummary, IScoreDetailList, IStatementReply, IVoter } from '@app/types/catalog.types';
import { generateScoreDescriptions } from '@components/common/summary/dimension-scores-suffix/dimension-scores-suffix.component';
import {
  createBenchmarksObject,
  filterDimensions,
  generateStatementScores,
  getUserProgress,
  modifyDimensionData,
  setSelectedStatementCount,
  updateReplyData,
} from '@components/views/assessment-view/assessment-view.utils';
import { IAssessmentTabOptions, IBaseTab } from '@components/page/assessment/assessment.types';
import { ReviewPermissionService } from '@root/src/services/review-permissions.service';
import { User } from '@app/types/user.types';
import { JSService } from '@services/js.service';
import { Review } from '@app/types/review.types';
import { ResultsFilterService } from '@services/filter.service';
import { updateCatalogDimensionById, updateDimensionField } from '@utils/review-helpers.utils';
import { ITab } from '@components/page/dimension-page/dimension-page.types';
import { resetUserScores } from '@components/common/dynamic-survey/dynamic-survey.utils';

export const setAssessmentState = (
  updatedAssessmentState: Partial<AssessmentStateInterface>,
  action: AssessmentActions,
  callbackFn?: (state: AssessmentStateInterface) => void,
): void => {
  useAssessmentStore.setState((state: AssessmentState) => ({
    assessmentState: {
      ...state.assessmentState,
      ...updatedAssessmentState,
    },
  }), false, action);

  if (callbackFn) {
    const { assessmentState } = useAssessmentStore.getState();
    callbackFn?.(assessmentState);
  }
}

export const getUnitRole = (
  unitId: string,
  onRequestError: (err: AxiosResponse) => void,
  callback: (roleInUnit: APP_ROLES | null) => void,
) => {
  UserAPI.getSelfUnitPermissions(unitId)
    .then((res) => {
      setRoleInUnitsAction(res.roleInUnit);
      ReviewPermissionService.roleInUnitData(res.roleInUnit);
      callback?.(res.roleInUnit);
    })
    .catch((e) => {
      onRequestError?.(e);
      callback?.(null);
    });
};

export const shareToUser = (userId: string, reviewId: string) => {
  ReviewAPI.share(reviewId, userId)
    .then(() => NotificationService.showSuccess(MESSAGE.SUCCESS.SHARED))
    .catch(ReviewService.showError);
};

export const attachDocuments = (
  asmtId: string,
  files: AttachedDocument[],
  reviewId: string,
  initNavBar: (tabIndex: number) => void,
  onRequestError: (err: AxiosResponse) => void,
  callbacks: { failed: () => void; success: () => void },
) => {
  addRequestStatus([API_REQUEST_KEY.attachDocuments]);
  Promise.all(
    files.map((document) =>
      ReviewAPI.uploadDocument({
        reviewId: asmtId,
        document: document.file,
        comment: document.comment,
      }),
    ),
  )
    .then(() => {
      loadAssessmentDocumentsAndVoters(2, reviewId, initNavBar, onRequestError);
      NotificationService.showSuccess('Document successfully added');
      callbacks.success();
    })
    .catch((error) => {
      ReviewService.showError(error);
      callbacks.failed();
    })
    .finally(() => {
      removeRequestStatus([API_REQUEST_KEY.attachDocuments]);
    });
};

export const printAssessment = () => {
  const { review } = useAssessmentStore.getState().assessmentState;

  addRequestStatus([API_REQUEST_KEY.printAssessment]);
  ReviewExportAPI.cheatsheet(review.id)
    .then((res) => {
      SupportService.generateFile(res, FILE_TYPE.PDF, '');
    })
    .catch(NotificationService.showError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.printAssessment]));
};

export const downloadPPTXReport = () => {
  const { review } = useAssessmentStore.getState().assessmentState;

  addRequestStatus([API_REQUEST_KEY.downloadPPTXReport]);

  ReviewExportAPI.pptx(review.id)
    .then((res) => {
      SupportService.generateFile(res, FILE_TYPE.PPTX, `Assessment Report - ${review.name}.pptx`);
    })
    .catch(NotificationService.showError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.downloadPPTXReport]));
};

export const downloadXLSXTable = () => {
  const { review } = useAssessmentStore.getState().assessmentState;

  addRequestStatus([API_REQUEST_KEY.downloadXLSXTable]);

  ReviewExportAPI.xlxs(review.id)
    .then((res) => {
      SupportService.generateFile(
        res,
        FILE_TYPE.XLSX,
        `Experts Score Details - ${review.name}.xlsx`,
      );
    })
    .catch(NotificationService.showError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.downloadXLSXTable]));
};

export const saveScoresBase = (
  reviewId: string,
  scores: {
    scoresByStatementId: IScoreDetailList;
  },
  callback: () => void,
) => {
  ReviewAPI.scoresStatistics(scores, reviewId)
    .then(() => {
      if (callback && JSService.isFunction(callback)) {
        callback();
      };
    })
    .catch((error) => {
      error?.status !== 400 && ReviewService.showError(error);
    });
};

export const loadAssessmentResults = (
  reviewId: string,
  tabIndex: number,
  initNavBar: (tabIndex: number) => void,
  onRequestError: (err: AxiosResponse) => void,
) => {
  addRequestStatus([API_REQUEST_KEY.loadAssessmentResults]);
  return ReviewAPI.results(reviewId, true)
    .then((review) => {
      setReviewAction(review);
      setReviewResultsAreSetAction(true);
      setShowSummarySectionAction(!!review.summary?.text || !!generateScoreDescriptions(review.dimensions));
      initNavBar(tabIndex);
    })
    .catch(onRequestError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.loadAssessmentResults]));
};

export const loadResultsFilter = (
  reviewId: string,
  voters: IVoter[],
  onRequestError: (err: AxiosResponse) => void,
) => {
  ReviewAPI.filter(reviewId)
    .then((filter) => {
      const filters = filter || { ...initialFilterData };
      if (!filters.minRange && filters.minRange !== 0) {
        filters.minRange = '';
      }
      if (!filters.maxRange && filters.maxRange !== 0) {
        filters.maxRange = '';
      }
      filters.voterIds = filters.voterIds.filter((voterId) =>
        voters.find((voter) => voter.id === voterId),
      );
      setFilterConfigAction(filters);
      checkFilterData(filters);
    })
    .catch(onRequestError);
};

export const checkFilterData = (filters: IFilterData) => {
  const { isReviewRespondent } = useAssessmentStore.getState().assessmentState;
  // check if any dimensions filter is applied
  if (isReviewRespondent) {
    setIsFilteredDimensionsAction(false);
  } else {
    setIsFilteredDimensionsAction(ResultsFilterService.checkFilterDimensions(filters));
  }

};

export const loadAssessmentDocumentsAndVoters = (
  tabIndex: number,
  reviewId: string,
  initNavBar: (tabIndex: number) => void,
  onRequestError: (err: AxiosResponse) => void,
) => {
  addRequestStatus([API_REQUEST_KEY.loadAssessmentDocumentsAndVoters]);

  ReviewAPI.get(reviewId, false)
    .then((review) => {
      ReviewAPI.documents(reviewId).then(
        ({ voterDocuments, reviewStatementDocuments, reviewDocuments }) => {
          const adaptedVoterDocuments = voterDocuments?.map((document) => {
            const { voters } = useAssessmentStore.getState().assessmentState;
            const voterName = voters?.find(({ id }) => id === document.voterId)?.voterName;

            return {
              ...document,
              linkedTo: {
                statementText: document.statementDescription,
                entityName: voterName ? `Page: ${voterName}` : `Page: ${document.createdBy?.name}`,
              },
            };
          });

          const adaptedReviewStatementDocuments = reviewStatementDocuments?.map((document) => ({
            ...document,
            linkedTo: {
              statementText: document.statementDescription,
              entityName: document.dimensionName,
            },
          }));

          const adaptedReviewDocuments = reviewDocuments?.map((document) => ({
            ...document,
            linkedTo: {
              statementText: document.asmtName,
              entityName: `Assessment #${document.asmtNumber}`,
            },
          }));
          setAssessmentState({
            voterDocuments: adaptedVoterDocuments,
            reviewStatementDocuments: adaptedReviewStatementDocuments,
            reviewDocuments: adaptedReviewDocuments,
          }, AssessmentActions.setReviewDocuments);
          setReviewAction(review);

          initNavBar(tabIndex);
        },
      ).then();
    })
    .catch(onRequestError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.loadAssessmentDocumentsAndVoters]));
};

export const removeVoter = (reviewId: string, voterId: string, voter: IVoter) => {
  ReviewVoterAPI.delete(reviewId, voterId)
    .then(() => {
      setVotersAction(useAssessmentStore.getState().assessmentState.voters?.filter((v) => v.id !== voterId) as IVoter[]);
      NotificationService.showSuccess(MESSAGE.SUCCESS.VOTER.DELETE(voter.voterName));
    })
    .catch(NotificationService.showError);
};

const handleVotersCallback = (voter: IVoter, voterId: string) => {
  const { voters, allVoters } = useAssessmentStore.getState().assessmentState;

  setVotersAction(voters?.map((v) => (v.id === voterId ? voter : v)) as IVoter[]);
  setAllVotersAction(allVoters?.map((v) => (v.id === voterId ? voter : v)) as IVoter[]);
}

export const updateVoter = (
  reviewId: string,
  voterId: string,
  voter: IVoter,
  silent = false,
)=> {
  ReviewVoterAPI.update(reviewId, voterId, voter)
    .then(() => {
      handleVotersCallback(voter, voterId);

      if (!silent) {
        NotificationService.showSuccess(MESSAGE.SUCCESS.VOTER.RENAME(voter.voterName));
      }
    })
    .catch((error) => {
      !silent && NotificationService.showError(error);
    });
};

export const unFinishVoter = (
  reviewId: string,
  voterId: string,
  voter: IVoter,
  silent = false,
  callback?: (() => void) | undefined
)=> {
  ReviewVoterAPI.unFinish(reviewId, voterId, voter)
    .then(() => {
      handleVotersCallback(voter, voterId);

      if (!silent) {
        NotificationService.showSuccess(MESSAGE.SUCCESS.VOTER.IN_PROGRESS)
      }
      callback?.(); // handle assessment tab
    })
    .catch((error) => {
      !silent && NotificationService.showError(error);
    });
};

export const onPageRead = (page: IBaseTab, callback: () => void) => {
  ReviewAPI.markAsRead(page.reviewId, page.id)
    .then(() => callback?.())
    // eslint-disable-next-line no-console
    .catch((error) => console.log(error));
};

export const saceScores = (
  reviewId: string,
  dimensions: CatalogDimension[],
  userId: string,
) => {
  const scoresByStatementId = generateStatementScores(dimensions, {}, userId);
  const scores = {
    scoresByStatementId,
  } as { scoresByStatementId: IScoreDetailList };

  ReviewAPI.scores(scores, reviewId)
    .then(() => {
      NotificationService.showSuccess(MESSAGE.SUCCESS.CHANGES_SAVED);
      updateDefaultScoresAndCopy(scoresByStatementId);
    })
    .catch(ReviewService.showError);
};

export const updateActiveDimensionId = (
  reviewId: string,
  voterId: string,
  dimensionId: string,
  callback: () => void,
) => {
  ReviewVoterAPI.updateActiveDimensionId(reviewId, voterId, dimensionId)
    .then(() => {
      callback?.();
    })
    .catch(ReviewService.showError);
};

export const finishAssessmentRequest = (reviewId: string, voterId: string, callback: (finishDate: string) => void) => {
  ReviewVoterAPI.finish(reviewId, voterId)
    .then((res) => {
      callback(res.finishDate);
    })
    .catch(NotificationService.showError);
};

export const saveReviewSummary = (id: string, summary: IDimensionSummary, callback?: (isActive?: boolean) => void) => {
  ReviewAPI.summary(id, summary)
    .then((res) => {
      const { review } = useAssessmentStore.getState().assessmentState;

      setReviewAction({ ...review, summary: res });

      NotificationService.showSuccess(MESSAGE.SUCCESS.COMMENT.SAVE);
      callback?.(false);
    })
    .catch(NotificationService.showError);
};

export const saveDimensionSummary = (id: string, summary: IDimensionSummary, callback?: (isActive: boolean) => void) => {
  ReviewCatalogAPI.summary(id, summary)
    .then((res) => {
      const { review } = useAssessmentStore.getState().assessmentState;
      const updatedDimensions = updateCatalogDimensionById(review.dimensions, id, { summary: res });

      setReviewAction({ ...review, dimensions: updatedDimensions });

      NotificationService.showSuccess(MESSAGE.SUCCESS.COMMENT.SAVE);
      callback?.(false);
    })
    .catch(NotificationService.showError);
};

export const saveBenchmark = (dimensions: CatalogDimension[], reviewId: string, initNavBar: (tabIndex: number) => void, onRequestError: (err: AxiosResponse) => void) => {
  const benchmarks = createBenchmarksObject(dimensions);

  addRequestStatus([API_REQUEST_KEY.saveBenchmark]);
  ReviewAPI.benchmark({ expectedScores: benchmarks })
    .then(() => loadAssessmentResults(reviewId, 1, initNavBar, onRequestError))
    .catch(ReviewService.showError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.saveBenchmark]));
};

export const updateResultsFilter = (reviewId: string, config: IFilterData, initNavBar: (tabIndex: number) => void, onRequestError: (err: AxiosResponse) => void) => {
  ReviewAPI.updateFilter(reviewId, config)
    .then((filter) => {
      const filters = filter || { ...initialFilterData };

      setFilterConfigAction(filters);
      checkFilterData(filters);
      loadAssessmentResults(reviewId, 1, initNavBar, onRequestError);
    })
    .catch(onRequestError);
};

export const deleteResultsFilter = (reviewId: string, initNavBar: (tabIndex: number) => void, onRequestError: (err: AxiosResponse) => void) => {
  ReviewAPI.deleteFilter(reviewId)
    .then(() => {
      const filters = { ...initialFilterData };

      setFilterConfigAction(filters);
      checkFilterData(filters);
      loadAssessmentResults(reviewId, 1, initNavBar, onRequestError);
    })
    .catch(onRequestError);
};

export const createResultsFilter = (reviewId: string, config: IFilterData, initNavBar: (tabIndex: number) => void, onRequestError: (err: AxiosResponse) => void) => {
  ReviewAPI.createFilter(reviewId, config)
    .then((filter) => {
      const filters = filter || { ...initialFilterData };

      setFilterConfigAction(filters);
      checkFilterData(filters);
      loadAssessmentResults(reviewId, 1, initNavBar, onRequestError);
    })
    .catch(onRequestError);
};

export const resetDependedStatements = (updatedStatements: AssessmentStatement[]) => {
  const { voters, voterNumber, activeTabId } = useAssessmentStore.getState().assessmentState;
  const { currentUser } = useGlobalStore.getState();

  const visibleIDs = filterVisibleStatementsIDs(updatedStatements as AssessmentStatement[]);
  const userId = currentUser?.id as string;
  const voter = voters?.find(voterItem => (voterItem.order === voterNumber && voterItem.id === activeTabId));

  return updatedStatements?.map((statement) => {
    if (!visibleIDs.includes(statement.id)) {
      return resetUserScores(
        statement,
        voter?.id || '',
        userId,
        true,
      );
    }
    return statement;
  }
)};

export const setProgress = (
  ignoreSave?: boolean,
  statements?: AssessmentStatement[],
  dimensionId?: string,
  isSurvey?: boolean,
  callback?: () => void,
) => {
  const { review: reviewData, autosave } = useAssessmentStore.getState().assessmentState;

  let updateDimensions = structuredClone(reviewData.dimensions);

  if (statements && dimensionId) {
    updateDimensions = updateDimensions.reduce((acc, dimension) => {
      const targetDimensionId = isSurvey ? dimension.id : dimensionId;

      return modifyDimensionData(
        acc,
        statements,
        targetDimensionId,
      );
    }, updateDimensions);
  }

  updateDimensions = setSelectedStatementCount(updateDimensions) as CatalogDimension[];

  const updatedReview = {
    ...reviewData,
    dimensions: updateDimensions,
  };

  setReviewAction(updatedReview);
  getUserProgress();

  if (!ignoreSave && autosave && ReviewPermissionService.isReviewParticipant() && !ReviewService.isFinished(reviewData)) {
    saveAssessment(callback);
  }
};

export const saveAssessment = (callback?: () => void) => {
  setIsManualRedirectedAction(true);
  saveAssessmentScores(callback);
};

export const filterVisibleStatementsIDs = (updatedStatements: AssessmentStatement[]) =>
  updatedStatements?.reduce((acc, st) => {
    if (st.visible) {
      acc.push(st.id);
    }
    return acc;
  }, [] as string[]);

export const saveAssessmentScores = (callback?: () => void) => {
  const { review: reviewData, reviewId } = useAssessmentStore.getState().assessmentState;
  const { currentUser } = useGlobalStore.getState();

  const scoresByStatementId = generateStatementScores(reviewData.dimensions, {}, (currentUser as User)?.id);
  const scores = { scoresByStatementId };

  saveScoresBase(reviewId, scores, () => {
    NotificationService.showSuccess(MESSAGE.SUCCESS.CHANGES_SAVED);
    updateDefaultScoresAndCopy(scoresByStatementId);

    if (callback && JSService.isFunction(callback)) {
      callback();
    };
  });
};

export const updateDefaultScoresAndCopy = (scoresByStatementId: IScoreDetailList) => {
  const { review: reviewData } = useAssessmentStore.getState().assessmentState;
  const updatedReview = structuredClone(reviewData);

  updatedReview.dimensions = setDefaultScores(updatedReview.dimensions, scoresByStatementId);
  setReview(updatedReview, setUserScoresCopy);
};

export const setDefaultScores = (dimensions: CatalogDimension[], scoresByStatementId: IScoreDetailList) => {
  const { currentUser } = useGlobalStore.getState();

  if (dimensions) {
    dimensions.map((dimension) => {
      dimension.statements.map((statement) => {
        if (scoresByStatementId[statement.id]) {
          statement.defaultScores[(currentUser as User)?.id] = JSService.getObjectCopy(
            statement.userScores[(currentUser as User)?.id],
          );
        }
        return statement;
      });

      dimension.dimensions = setDefaultScores(dimension.dimensions, scoresByStatementId);

      return dimension;
    });
  }
  return dimensions;
};

export const setUserScoresCopy = () => {
  const { currentUser } = useGlobalStore.getState();
  const { review: reviewData } = useAssessmentStore.getState().assessmentState;
  const userScores = generateStatementScores(reviewData.dimensions, {}, (currentUser as User)?.id);

  setAssessmentState({ userScoresCopy: JSService.getObjectCopy(userScores) }, AssessmentActions.setUserScoresCopy);
};

export const setReview = (reviewData: Review, callback: () => void) => {
  const { isReviewRespondent, filterConfig } = useAssessmentStore.getState().assessmentState;

  setReviewAction(reviewData);
  setReviewIdAction(reviewData.id);
  filterDimensions(reviewData.dimensions, isReviewRespondent, filterConfig);
  callback?.();
};

export const resetAssessmentState = (): void => {
  useAssessmentStore.setState(() => ({
    assessmentState: initialAssessmentState as AssessmentStateInterface,
    reviewId: '',
  }), false, AssessmentActions.resetAssessmentState);
};

export const setReply = (
  replyId: string,
  reply: IStatementReply | null,
  voterId: string,
  isExist: boolean,
) => {
  const { allVoters } = useAssessmentStore.getState().assessmentState;

  setAllVotersAction(updateReplyData(allVoters as IVoter[], replyId, reply, voterId, isExist));
};

export const handleAssessmentConfig = (
  review: Review,
) => {

  setAssessmentState({
    isFinished: ReviewService.isFinished(review),
  }, AssessmentActions.setIsReviewDataPrepared);
};

export const handleSunburstChartData = (
  preparedChartData: SunburstChartDataType,
) => {

  setAssessmentState({ sunburstChartData: preparedChartData }, AssessmentActions.setSunburstChartData);
};

export const resetResultTopLevelChartHistory = () => {

  setAssessmentState({
    topLevelHistoryDataIds: [],
    topLevelHistoryDataNames: [],
    selectedResultPageDimensionId: '',
    selectedResultPageDimensionName: '',
  }, AssessmentActions.resetResultTopLevelChartHistory);
};

export const setUpdatedDimensionSummary = (updatedDimension: CatalogDimension, summary: IDimensionSummary) => {
  const { review } = useAssessmentStore.getState().assessmentState;
  const updatedDimensions = structuredClone(review.dimensions).map((dimension) =>
    updateDimensionField(updatedDimension.id, dimension, { summary }),
  );

  setReviewAction({ ...review, dimensions: updatedDimensions as CatalogDimension[] });
};

export const updateOpenedIDsAction = (openedIDs: string[]) => {
  setAssessmentState({
    openedIDs,
  }, AssessmentActions.updateOpenedIDs);
};

export const updateReviewDimensionsAction = (dimensions: CatalogDimension[]) => {
  const { review } = useAssessmentStore.getState().assessmentState;

  setAssessmentState({
    review: {
      ...review,
      dimensions,
    }
  }, AssessmentActions.updateReviewDimensions);
};

export const setRemovedVoterIdAction = (removedVoterId: string) => {
  setAssessmentState({
    removedVoterId,
  }, AssessmentActions.removedVoterId);
};

export const setExpertAvgScoreAction = (expertAvgScore: number | null) => {
  setAssessmentState({
    expertAvgScore,
  }, AssessmentActions.setExpertAvgScore);
};

export const sunburstChartAction = (
  topLevelHistoryDataIds: string[],
  topLevelHistoryDataNames: string[],
  selectedResultPageDimensionId: string,
  selectedResultPageDimensionName: string,
  action: AssessmentActions,
) => {
  setAssessmentState({
    topLevelHistoryDataIds,
    topLevelHistoryDataNames,
    selectedResultPageDimensionId,
    selectedResultPageDimensionName,
  }, action);
};

export const setActiveTabIdAction = (activeTabId: string) => {
  setAssessmentState({
    activeTabId,
  }, AssessmentActions.setActiveTabId);
};

export const setFilteredDimensionsAction = (filteredDimensions: CatalogDimension[] | undefined) => {
  setAssessmentState({
    filteredDimensions,
  }, AssessmentActions.setFilteredDimensions);
};

export const setReviewAction = (review: Review) => {
  setAssessmentState({
    review,
  }, AssessmentActions.setReview);
};

export const setUserProgressAction = (userProgress: number) => {
  setAssessmentState({
    userProgress,
  }, AssessmentActions.setUserProgress);
};

export const setOptionsAction = (options: IAssessmentTabOptions | undefined) => {
  setAssessmentState({
    options,
  }, AssessmentActions.setOptions);
};

export const setFilteredDimensionsByNavigationAction = (filteredDimensionsByNavigation: CatalogDimension[]) => {
  setAssessmentState({
    filteredDimensionsByNavigation,
  }, AssessmentActions.setFilteredDimensionsByNavigation);
};

export const onResultDimensionSelectAction = (
  filteredDimensionsByNavigation: CatalogDimension[],
  selectedResultPageDimensionId: string,
  selectedResultPageDimensionName: string,
  topLevelHistoryDataIds: string[],
  topLevelHistoryDataNames: string[],
  openedIDs: string[],
  expertAvgScore: number | null,
) => {
  setAssessmentState({
    filteredDimensionsByNavigation,
    selectedResultPageDimensionId,
    selectedResultPageDimensionName,
    topLevelHistoryDataIds,
    topLevelHistoryDataNames,
    openedIDs,
    expertAvgScore,
  }, AssessmentActions.onResultDimensionSelect);
};

export const setRoleInUnitsAction = (roleInUnit: APP_ROLES | null) => {
  setAssessmentState({
    roleInUnit,
  }, AssessmentActions.setRoleInUnits);
};

export const setVotersAction = (voters: IVoter[] | null) => {
  setAssessmentState({
    voters,
  }, AssessmentActions.setVotersAction);
};

export const setAllVotersAction = (allVoters: IVoter[] | null) => {
  setAssessmentState({
    allVoters,
  }, AssessmentActions.setAllVotersAction);
};

export const setReviewIdAction = (reviewId: string) => {
  setAssessmentState({
    reviewId,
  }, AssessmentActions.setReviewId);
};

export const setIsManualRedirectedAction = (isManualRedirected: boolean) => {
  setAssessmentState({
    isManualRedirected,
  }, AssessmentActions.setIsManualRedirected);
};

export const setAssessmentTabsAction = (tabs?: ITab[] | undefined) => {
  setAssessmentState({
    tabs,
  }, AssessmentActions.setAssessmentTabs);
};

export const setAutosaveAction = (autosave: boolean) => {
  setAssessmentState({
    autosave,
  }, AssessmentActions.setAutosave);
};

export const setWithFiltersAction = (withFilter: boolean) => {
  setAssessmentState({
    withFilter,
  }, AssessmentActions.setWithFilters);
};

export const setIsAllDimensionsOpenedAction = (isAllDimensionsOpened: boolean) => {
  setAssessmentState({
    isAllDimensionsOpened,
  }, AssessmentActions.setIsAllDimensionsOpened);
};

export const setTouchedAction = (touched: any) => {
  setAssessmentState({
    touched,
  }, AssessmentActions.setTouched);
};

export const setVoterNumberAction = (voterNumber: number) => {
  setAssessmentState({
    voterNumber,
  }, AssessmentActions.setVoterNumber);
};

export const setFilterConfigAction = (filterConfig: IFilterData) => {
  setAssessmentState({
    filterConfig,
  }, AssessmentActions.setFilterConfig);
};

export const setReviewResultsAreSetAction = (reviewResultsAreSet: boolean) => {
  setAssessmentState({
    reviewResultsAreSet,
  }, AssessmentActions.setReviewResultsAreSet);
};

export const setChartTypeAction = (chartType: string) => {
  setAssessmentState({
    chartType,
  }, AssessmentActions.setChartType);
};

export const setShowSummarySectionAction = (showSummarySection: boolean | undefined) => {
  setAssessmentState({
    showSummarySection,
  }, AssessmentActions.setShowSummarySection);
};

export const setReviewVotesAreSetAction = (reviewVotesAreSet: boolean) => {
  setAssessmentState({
    reviewVotesAreSet,
  }, AssessmentActions.setReviewVotesAreSet);
};
export const setIsReviewRespondentAction = (isReviewRespondent: boolean) => {
  setAssessmentState({
    isReviewRespondent,
  }, AssessmentActions.setIsReviewRespondent);
};

export const setMetricsActiveAction = (metricsActive: boolean) => {
  setAssessmentState({
    metricsActive,
  }, AssessmentActions.setMetricsActive);
};

export const setIsFilteredDimensionsAction = (isFilteredDimensions: boolean) => {
  setAssessmentState({
    isFilteredDimensions,
  }, AssessmentActions.setIsFilteredDimensions);
};

export const setDimensionsWithScoreAction = (dimensionsWithScore: CatalogDimension[]) => {
  setAssessmentState({
    dimensionsWithScore,
  }, AssessmentActions.setDimensionsWithScore);
};

export const setShowScoreInputAction = (showScoreInput: boolean) => {
  setAssessmentState({
    showScoreInput,
  }, AssessmentActions.setShowScoreInput);
};

export const setCurrentVoterAction = (currentVoter: IVoter) => {
  setAssessmentState({
    currentVoter,
  }, AssessmentActions.setCurrentVoter);
};
