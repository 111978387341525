/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  MainColorPalette,
  AdditionalColorPalette,
  TextColorPalette,
  grayVerylightColor,
  primaryHighlight,
  sourceSansPro,
  whiteColor,
  blackColor,
  darkOrchid,
} from '@utils/variables';

export const richEditor = css`
  border-radius: 3px;
  border: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  width: 100%;
  padding: 0 13px;
  background-color: white;

  &:not(:last-child) {
    margin-bottom: 4px;
    flex-grow: 1;
  }
`;

export const richEditorHighlighted = css`
  border: 1px solid ${primaryHighlight};
`;

export const richEditorQlSeparator = css`
  display: flex;
  align-items: center;
  /* stylelint-disable-next-line */
  &:not(:last-child)::after {
    content: '';
    height: 100%;
    width: 1px;
    background-color: ${AdditionalColorPalette.Greyscale[300]};
    margin: 0 6px;
  }
`;

export const richEditorQlCustomSeparator = css`
  max-width: fit-content;
  margin-left: auto;
  margin-right: 76px;
`;

export const StyledRichEditor = styled.div`
  .ql-editor {
    min-height: 80px;
    padding: 10px 0;
    font-size: 14px;
    font-family: ${sourceSansPro};
    background-color: ${whiteColor};
    border-radius: 3px;
    color: ${AdditionalColorPalette.Greyscale[700]};
    position: relative;

    &.ql-blank {
      &:before {
        font-style: normal;
        color: ${grayVerylightColor};
        left: 0;
      }
    }

    p {
      margin: 0 !important;
    }

    /* stylelint-disable-next-line */
    observation, recommendation, results {
      &:before,
      &:after {
        display: none !important;
      }
    }
  }

  .ql-flip {
    z-index: 1;
    width: auto !important;
  }

  .ql-toolbar {
    display: flex;
    max-width: 100%;
    padding: 10px 0 !important;
    border: none !important;
    border-bottom: 1px solid ${AdditionalColorPalette.Greyscale[200]} !important;

    button {
      float: none !important;
      vertical-align: middle;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    .ql-active {
      color: ${whiteColor} !important;
      background-color: ${AdditionalColorPalette.Greyscale[200]} !important;
      border-radius: 2px;

      .ql-stroke {
        stroke: ${MainColorPalette.Grey[500]} !important;
      }
    }
  }

  .ql-container {
    border: none !important;
  }
  /* stylelint-disable-next-line */
  .ql-snow .ql-stroke {
    stroke: ${MainColorPalette.Grey[500]};
  }

  .ql-snow .ql-fill {
    fill: ${MainColorPalette.Grey[500]} !important;
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
    stroke: ${MainColorPalette.Grey[500]};
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-radius: 3px;
    outline: none;
    top: calc(100% + 6px) !important;
    left: -2px;
    z-index: 3;
  }

  .ql-color {
    &[value='${blackColor}'] {
      background-color: ${blackColor} !important;
    }

    &[value='${AdditionalColorPalette.Red[700]}'] {
      background-color: ${AdditionalColorPalette.Red[700]} !important;
    }

    &[value='${MainColorPalette.LightBlue[600]}'] {
      background-color: ${MainColorPalette.LightBlue[600]} !important;
    }

    &[value='${MainColorPalette.LightGreen[700]}'] {
      background-color: ${MainColorPalette.LightGreen[700]} !important;
    }
    /* stylelint-disable-next-line */
    &.ql-picker .ql-picker-options {
      width: auto;
      border-color: ${AdditionalColorPalette.Greyscale[300]} !important;
      box-shadow: none;
    }

    .ql-picker-label {
      outline: none;
      border: none !important;
    }
  }

  .ql-background {
    width: auto !important;
    height: auto !important;
    border: 1px solid ${AdditionalColorPalette.Greyscale[300]} !important;
    border-radius: 3px;
    color: ${TextColorPalette.Primary} !important;
    background-color: ${whiteColor} !important;

    [data-value='${whiteColor}'] {
      /* stylelint-disable-next-line */
      &::after {
        content: '#No tags';
      }
    }

    [data-value='${MainColorPalette.LightYellow[50]}'] {
      /* stylelint-disable-next-line */
      &::after {
        content: '#Observation';
      }
    }

    [data-value='${MainColorPalette.LightGreen[50]}'] {
      /* stylelint-disable-next-line */
      &::after {
        content: '#Recommendation';
      }
    }

    [data-value='${MainColorPalette.LightBlue[50]}'] {
      /* stylelint-disable-next-line */
      &::after {
        content: '#Results';
      }
    }

    .ql-picker-item {
      display: flex;
      width: auto !important;
      height: auto !important;
      outline: none;
      border-radius: 3px;
      border: none !important;
      color: ${TextColorPalette.Primary} !important;
      font-weight: normal;
      padding: 3px 8px !important;

      &[data-value='${whiteColor}'] {
        border: 1px solid ${AdditionalColorPalette.Greyscale[300]} !important;
      }
    }
    /* stylelint-disable-next-line */
    &.ql-picker .ql-picker-options {
      width: auto;
      border-color: ${AdditionalColorPalette.Greyscale[300]} !important;
      box-shadow: none;
      left: -8px !important;
    }

    .ql-picker-label {
      outline: none;
      border: none !important;
      display: inline-block;
      width: auto;
      padding: 3px 8px !important;
      background-color: ${whiteColor} !important;
      color: ${TextColorPalette.Primary} !important;
      font-weight: normal;

      &.ql-active {
        &[data-value='${whiteColor}'] {
          background-color: ${whiteColor} !important;
        }

        &[data-value='${MainColorPalette.LightYellow[50]}'] {
          background-color: ${MainColorPalette.LightYellow[50]} !important;
        }

        &[data-value='${MainColorPalette.LightGreen[50]}'] {
          background-color: ${MainColorPalette.LightGreen[50]} !important;
        }

        &[data-value='${MainColorPalette.LightBlue[600]}'] {
          background-color: ${MainColorPalette.LightBlue[600]} !important;
        }
      }
    }

    svg {
      display: none;
    }
  }
`;

export const editorCloseIconSection = css`
  margin-left: auto;
`;

export const richEditorCustomButton = css`
  display: flex;
  flex-direction: row;
  width: 110px;
  font-weight: 600;
  color: ${darkOrchid};
  align-items: center;
`;
