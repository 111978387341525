/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ChangeEvent } from 'react';
import { StyledSwitch } from '@app/components/element/switch/switch.style';
import {
  toggleSwitchDescription,
  toggleSwitchLi,
} from '@app/components/element/toggle-menu/toggle-menu.style';

interface SwitchProps {
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  style?: { [key: string]: string | number };
};

const Switch = ({ label, checked, onChange, style }: SwitchProps) => (
  <div css={toggleSwitchLi} style={style}>
    <span css={toggleSwitchDescription}>{label}</span>
    <StyledSwitch checked={checked} onChange={onChange} />
  </div>
);

export default Switch;
