/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ReactElement, useRef, useState } from 'react';
import { MoreVert18 } from '@core/icons/MoreVert18';
import { MoreVert24 } from '@core/icons/MoreVert24';
import { IconButton, MenuItem, Popper, ClickAwayListener } from '@mui/material';
import { Divider, Tooltip } from '@perf/ui-components';
import {
  buttonLikeStyle,
  menuIconStyles,
  MenuPaper,
  StyledMenu,
  toggleDividerStyles,
} from '@app/components/element/toggle-menu/toggle-menu.style';
import { IToggleMenuOption } from '@components/element/toggle-menu/toggle-menu.types';

interface ToggleMenuProps {
  content?: ((attr: any) => any) | null;
  options?: IToggleMenuOption[];
  isButtonStyle?: boolean;
  size?: number;
  color?: string;
  customIcon?: ReactElement;
  style?: object,
  isDisabled?: boolean;
  isSubMenu?: boolean;
  parentHandleClose?: () => void;
}

const ToggleMenu = ({
  content,
  options,
  isButtonStyle,
  size = 24,
  color,
  customIcon,
  style,
  isDisabled,
  isSubMenu,
  parentHandleClose,
}: ToggleMenuProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    if (isDisabled) {
      return;
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: MouseEvent | TouchEvent) => {
    if (anchorRef.current && (anchorRef.current as any).contains(event?.target)) {
      return;
    }
    setOpen(false);
    parentHandleClose?.();
  };

  const optionAction = (action: () => void) => {
    handleClose();
    action();
  };

  const getDefaultIcon = (iconSize: number) =>
    iconSize === 18 ? <MoreVert18 color="inherit" /> : <MoreVert24 color="inherit" />;

  return (
    <>
      <IconButton
        onClick={handleToggle}
        disableRipple
        sx={{
          padding: 0,
          color,
          width: 24,
          height: 24,
          ...(isButtonStyle && buttonLikeStyle),
          ...style,
        }}
        ref={anchorRef}
      >
        {!customIcon ? getDefaultIcon(size) : customIcon}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        disablePortal
        sx={{
          zIndex: 1001,
          ...(isSubMenu ? { transform: 'translate(-212px, 36px) !important' } : {}),
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuPaper elevation={0}>
            <StyledMenu>
              {options?.map((option) => (
                <Tooltip
                  key={`toggle-menu-tooltip-${option.label}`}
                  content={option?.hint}
                  placement="top"
                  disabled={!option?.hint}
                >
                  {option?.isSubOption ?
                    <ToggleMenu
                      options={option?.options}
                      customIcon={option.label as ReactElement}
                      parentHandleClose={handleClose}
                      isSubMenu
                      style={{
                        width: '100%',
                        justifyContent: 'flex-start',
                        padding: 0,
                        height: '36px',
                      }}
                    /> :
                    <div>
                      <MenuItem
                        onClick={() => optionAction(option.action)}
                        disabled={option?.disabled}
                      >
                        <div css={menuIconStyles}>{option.icon}</div>
                        {option.label}
                      </MenuItem>
                      {
                        option?.showDivider &&
                        <div>
                          <Divider css={toggleDividerStyles} />
                        </div>
                      }
                    </div>
                  }
                </Tooltip>
              ))}
              {content && (
                <div>
                  <Divider css={toggleDividerStyles} />
                  {content(handleClose)}
                </div>
              )}
            </StyledMenu>
          </MenuPaper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default ToggleMenu;
