/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { InfoOutline18 } from '@core/icons/InfoOutline18';
import { ModalDialog, Tooltip, Typography } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { modalErrorStyles, summaryBodyContainer } from './generate-summary-modal.style';
import { ReviewAPI } from '@api/services/review-api.resources';
import { modalStaticHeight } from '@app/components/modal/modal.style';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import ModalLoader from '@components/modal/modal-loader/modal-loader.component';

const TitleTemplate = () => (
  <Typography color="inherit" noWrap variant="h4">
    <span css={[{ marginRight: '8px' }]}>Generate summary</span>
    <Tooltip
      content="AI recommendations are generated based on the results of the assessment.
    Use filters if necessary to filter out information."
      placement="top"
      css={[{ maxWidth: '400px' }]}
    >
      <InfoOutline18 />
    </Tooltip>
  </Typography>
);

const GeneratedSummaryBody = ({ summaryText, errorMessage }) =>
  errorMessage ? (
    <span css={modalErrorStyles}>{errorMessage}</span>
  ) : (
    // eslint-disable-next-line react/self-closing-comp
    <div
      css={summaryBodyContainer}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: summaryText,
      }}
    ></div>
  );

const GenerateSummaryModal = ({ addGeneratedText, reviewId, dimensionId, onClose }) => {
  const [show, setShow] = useState(true);
  const [summaryText, setSummaryText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
      onClose?.();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    ReviewAPI.getGeneratedSummary({
      reviewId,
      ...(dimensionId ? { dimensionId } : {}),
    })
      .then((data) => setSummaryText(data))
      .catch(() => setErrorMessage('Sorry, the error occurred. Please try again later.'))
      .finally(() => setIsLoading(false));
  }, [reviewId, dimensionId]);

  return (
    <ModalDialog
      hidePadding
      config={{
        body: !isLoading ? (
          <GeneratedSummaryBody summaryText={summaryText} errorMessage={errorMessage} />
        ) : (
          <ModalLoader message="Generation in progress..." />
        ),
        handleCancel: handleClose,
        confirmText: 'Add to summary',
        handleConfirm: () => {
          addGeneratedText(summaryText);
          setShow(false);
        },
      }}
      container={getRootTarget}
      fullWidth
      isShow={show}
      disableSave={isLoading || !summaryText}
      maxWidthNumber={560}
      css={modalStaticHeight(560)}
      titleTemplate={<TitleTemplate />}
    />
  );
};

GeneratedSummaryBody.propTypes = {
  summaryText: PropTypes.string,
  errorMessage: PropTypes.string,
};

GenerateSummaryModal.propTypes = {
  addGeneratedText: PropTypes.func,
  reviewId: PropTypes.string,
  dimensionId: PropTypes.string,
};

export default GenerateSummaryModal;
