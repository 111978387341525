/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import {
  Input,
  ModalDialog,
  TextArea,
  Select,
  Tree,
  Tooltip,
  SimpleEvent,
  GenericCheckbox,
} from '@perf/ui-components';
import { OptionType } from '@perf/ui-components/dist/types/Dropdown.types';
import ModalIndicatorDescription from '@components/modal/statement-modal/modal-indicator-description/modal-indicator-description.component';
import { CatalogTaxonomyAPI } from '@api/services/catalog-api.resources';
import { StatementDocumentAPI } from '@api/services/statement-api.resources';
import { generalInputField } from '@root/src/components/common-styled/input.style';
import {
  modalGrayWrapper,
  modalInfo,
  modalInput,
  modalInputSmall,
  modalText,
  modalBody,
} from '@app/components/modal/modal.style';
import {
  metricNameInputStyle,
  unitOfMeasureInputStyle,
  doubleField,
  leftSide,
  leftSideBorder,
  rightSideOfModal,
  selectTypeField,
  statementModalBody,
  statementModalDescription,
  statementModalRatingGroup,
  statementModalYesNoGroup,
  statementModalYesNoScoreSelect,
  statementWeightDescription,
  statementWeightField,
  yesNoFieldRightSide,
  statementModalWrapper,
  statementTextCheckbox,
  statementModalIndicatorInput,
  ratingIndicatorScore,
  indicatorScore,
  commonIndicatorScore,
  statementModalYesNoRow,
  inputName,
} from '@app/components/modal/statement-modal/statement-modal.style';
import {
  taxonomiesList,
  taxonomiesListRootItemName,
  taxonomiesListWrapper,
} from '@app/components/search/search-taxonomies-tree/search-taxonomies-tree.style';
import { NotificationService } from '@app/services/notification.service';
import { ReviewService } from '@app/services/review.service';
import { SpinnerService } from '@app/services/spinner.service';
import { adaptTags, isTypeHasRating, setDefaultIndicatorInfo } from '@app/utils/dimensions.utils';
import { downloadStatementDocument } from '@app/utils/download-statement-document.utils';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { isMetricBaseCheckError } from '@app/utils/metric.utils';
import { trimStatementAnswers, isRulesAffected } from '@app/utils/statement-helpers.utils';
import { collectTags } from '@app/utils/tree.utils';
import Metric from '@components/common/metric-question/metric-choices/metric-choices.component';
// @ts-ignore
import MultiChoices from '@components/common/multi-choices/multi-choices.component';
// @ts-ignore
import RadioChoices from '@components/common/radio-choices/radio-choices.component';
// @ts-ignore
import SupportingInfo from '@components/common/supporting-info/supporting-info.component';
import Score from '@components/element/score/score.component';
import Switch from '@components/element/switch/switch.component';
import { InheritCheckBoxes } from '@components/modal/inherit-check-boxes/inherit-check-boxes.component';
import {
  CATALOG_WEIGHT_INFO,
  DEFAULT_INDICATORS,
  DEFAULT_WEIGHT,
  MAX_WEIGHT_LENGTH,
  MESSAGE,
  NAME_MAX_LENGTH,
  NOTE_MAX_LENGTH,
  SCALE_TYPE,
  SCORE_INDICATOR,
  SCORE_SCALE,
  RESOURCE_TYPES,
  ANSWER_TYPES,
} from '@lib/common.constants';
import { DIMENSION_FIELDS } from '@lib/data-fields.constants';
import { INHERIT_INDICATORS } from '@lib/event.constants';
import { INHERIT_TOOLTIP_MESSAGE, SPECIFY_LEVEL_NAME_MESSAGE } from '@lib/ui-text-block.constants';
import { CatalogService } from '@services/catalog.service';
import { JSService } from '@services/js.service';
import { ModalService } from '@services/modal.service';
import { ScaleService } from '@services/scale.service';
import MaterialSelect from '@components/element/material-select/material-select.component';
import {
  Statement,
  IndicatorChoice,
  IndicatorInfo,
  Dimension,
  Taxonomies,
  SupportingQuestion,
  AttachedDocument,
  StatementDocument,
  Tag,
  Taxonomy,
  DimensionTag,
} from '@root/src/types/dimension.types';
import { setLoadingStatus } from '@app/store/global.actions';

const METRIC_MEASURE_MAX_LENGTH = 10;

const types = Object.keys(SCALE_TYPE).map((type) => ({
  id: type,
  name: SCALE_TYPE[type].label,
}));

const isChoicesDuplicates = (choices: IndicatorChoice[]) => {
  const choiceSet = new Set();

  for (const choiceObj of choices) {
    const choice = choiceObj.choice;

    if (choiceSet.has(choice)) {
      return true;
    }
    choiceSet.add(choice);
  }

  return false;
};

const isChoicesEmpty = (choices: IndicatorChoice[]) =>
  choices.some((choice) => !choice.choice?.trim());

const mapStatementTaxonomies = (statementTags: DimensionTag[], categories: Taxonomies[]) => {
  const categoryMapping = categories.map((category) => ({
    id: category.id,
    tags: collectTags(category).map((item: { [key: string]: string }) => item.id),
  }));
  const categoriesObject = {};

  statementTags?.forEach((tag) => {
    const categoryId = categoryMapping.find((category) => category.tags.includes(tag.id))
      ?.id as string;

    categoriesObject[categoryId] = [...(categoriesObject[categoryId] || []), tag.id];
  });
  return categoriesObject;
};

const collectStatementTags = (categories: Taxonomy, taxonomyTree: Taxonomies[]) => {
  const allTags = taxonomyTree
    // create flat list from all nested tags in a tree
    .map((category) => collectTags(category))
    .flat();

  return (
    Object.values(categories)
      // get ids of all selected tags and create tag object
      .flat()
      .map((tagId) => ({
        id: tagId,
        name: allTags.find((tag) => tag.id === tagId)?.name,
      }))
  );
};

interface StatementModalProps {
  title: string;
  statement: Statement;
  isDisabled: boolean;
  onAccept: <T>(trimStatementAnswers: T, documentIds: string[], isRuleReset: boolean) => void;
  isReviewStructure: boolean;
  resource: string;
  onDocumentAttached: (id: string) => void;
  onDocumentRemoved: (docId: string, statement: Statement) => void;
  catalogDocIds: string[];
  isDocumentsDisabled: boolean;
  isInheritedDisabled: boolean;
}

const StatementModal = ({
  onAccept,
  isDisabled,
  title,
  statement: initialStatement,
  isReviewStructure,
  resource,
  onDocumentAttached,
  onDocumentRemoved,
  catalogDocIds = [],
  isDocumentsDisabled,
  isInheritedDisabled,
}: StatementModalProps) => {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [taxonomies, setTaxonomies] = useState<Taxonomies[]>([]);
  const [prevStatementData, setPrevStatementData] = useState<Statement>({} as Statement);
  const [statementTaxonomies, setStatementTaxonomies] = useState<Taxonomy>({});
  const [statement, setStatement] = useState(
    JSService.isUndefinedOrNull(initialStatement.weight)
      ? { ...initialStatement, weight: DEFAULT_WEIGHT }
      : initialStatement,
  );
  const [documentIds, setDocumentIds] = useState<string[]>([]);

  useEffect(() => {
    if (statement) {
      onSelectType(statement.type, false);
      handleSetPrevStatement(statement);
      loadTaxonomies();
    } else {
      setShow(true);
    }
  }, []);

  const loadTaxonomies = () => {
    setLoadingStatus(true);
    CatalogTaxonomyAPI.getAll()
      .then((categories) => {
        setTaxonomies(categories as Taxonomies[]);
        setStatementTaxonomies(
          mapStatementTaxonomies(initialStatement.tags, categories as Taxonomies[]),
        );
        setShow(true);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingStatus(false));
  };


  const calculateInitialRateStatementActive = () => {
    if (
      ScaleService.isMultiGroup(initialStatement.type) ||
      ScaleService.isOpenEnded(initialStatement.type)
    ) {
      return initialStatement.rated || false;
    }
    return false;
  };
  const [rateStatementActive, setRateStatementActive] = useState(
    calculateInitialRateStatementActive(),
  );
  const isSubStatement =
    statement.parentDimension ||
    !statement?.parentId ||
    !JSService.isEmptyOrNullObject(statement?.hasParent);

  const isInheritOptionVisible = () =>
    isSubStatement &&
    !isInheritedDisabled &&
    (ScaleService.isRating(statement.type) ||
      (ScaleService.isMultiGroup(statement.type) && rateStatementActive) ||
      (ScaleService.isOpenEnded(statement.type) && rateStatementActive));

  const updatePrevStatement = (updatedStatement: Statement) => {
    if (updatedStatement?.type) {
      setPrevStatementData((prevState) => {
        if (isTypeHasRating(updatedStatement.type)) {
          Object.keys(updatedStatement?.indicatorInfo)?.forEach((indicatorId) => {
            updatedStatement.indicatorInfo[indicatorId].name =
              prevStatementData?.[updatedStatement.type]?.indicatorInfo[indicatorId]?.name ||
              DEFAULT_INDICATORS[updatedStatement?.type][indicatorId].name;

            updatedStatement.indicatorInfo[indicatorId].description =
              prevStatementData?.[updatedStatement.type]?.indicatorInfo[indicatorId]?.description ||
              DEFAULT_INDICATORS[updatedStatement?.type][indicatorId].description;
          });
        }
        return {
          ...prevState,
          [updatedStatement.type]: {
            indicatorInfo: updatedStatement.indicatorInfo,
            indicatorChoices: updatedStatement.indicatorChoices,
          },
        };
      });
    }
  };

  const getParentIndicatorInfo = (indicatorId: string, field: string) => {
    if (
      JSService.isEmptyOrNullObject(statement.parentDimension?.indicatorInfo) &&
      JSService.isEmptyOrNullObject(statement?.parentIndicatorInfo)
    ) {
      return;
    }

    const parentIndicatorInfo = JSService.isEmptyOrNullObject(
      statement.parentDimension?.indicatorInfo,
    )
      ? setDefaultIndicatorInfo(statement.parentIndicatorInfo)
      : setDefaultIndicatorInfo((statement.parentDimension as Dimension).indicatorInfo);

    return parentIndicatorInfo[indicatorId]?.[field];
  };

  const updateIndicatorName = (
    tempIndicators: IndicatorInfo,
    indicatorId: string,
    type: ANSWER_TYPES,
  ) => {
    const parentIndicatorName = getParentIndicatorInfo(indicatorId, DIMENSION_FIELDS.NAME) || '';
    tempIndicators[indicatorId].name =
      parentIndicatorName || DEFAULT_INDICATORS[type][indicatorId].name;
  };

  const updateIndicatorDescription = (
    tempIndicators: IndicatorInfo,
    indicatorId: string,
    type: ANSWER_TYPES,
  ) => {
    const parentIndicatorDescription = getParentIndicatorInfo(
      indicatorId,
      DIMENSION_FIELDS.DESCRIPTION,
    );
    tempIndicators[indicatorId].description =
      parentIndicatorDescription || DEFAULT_INDICATORS[type][indicatorId].description;
    if (!tempIndicators[indicatorId].description) {
      tempIndicators[indicatorId].isEditMode = false;
    }
  };

  const initializeIndicators = (type: ANSWER_TYPES, isPrevData: boolean) => {
    const initialIndicators = structuredClone(
      statement?.type === type && !JSService.isEmptyOrNullObject(statement?.indicatorInfo)
        ? statement?.indicatorInfo
        : DEFAULT_INDICATORS[type],
    );

    return isPrevData && prevStatementData[type]?.indicatorInfo
      ? structuredClone(prevStatementData[type].indicatorInfo)
      : initialIndicators;
  };

  const handleSetPrevStatement = (updatedStatement: Statement) => {
    setPrevStatementData((prevState) => ({
      ...prevState,
      [statement.type]: {
        indicatorInfo: updatedStatement.indicatorInfo,
        indicatorChoices: updatedStatement.indicatorChoices,
      },
    }));
  };

  const prepareIndicators = (type: ANSWER_TYPES, inheritFields: string[]) => {
    const prevData = prevStatementData[type];
    const isPrevData =
      !JSService.isEmptyOrNullObject(prevData) &&
      !JSService.isEmptyOrNullObject(prevData?.indicatorInfo);
    const isCheckName = inheritFields.includes(INHERIT_INDICATORS.LEVEL_NAME);
    const isCheckDescription = inheritFields.includes(INHERIT_INDICATORS.LEVEL_DESC);
    const tempIndicators = initializeIndicators(type, isPrevData);

    if (isTypeHasRating(type) && inheritFields?.length) {
      if (!isPrevData && (isCheckName || isCheckDescription)) {
        handleSetPrevStatement(statement);
      }

      Object.keys(tempIndicators).forEach((indicatorId) => {
        if (isCheckName) updateIndicatorName(tempIndicators, indicatorId, type);
        if (isCheckDescription) updateIndicatorDescription(tempIndicators, indicatorId, type);
      });
    }

    return tempIndicators;
  };

  const onClose = (event: MouseEvent<HTMLElement>, canceled = false) => {
    if (ModalService.isOnCancelClick(event)) {
      if (canceled && !statement.id) {
        documentIds.forEach((documentId) => deleteDocument(documentId));
      }
      setShow(false);
    }
  };

  const onDataChange = (event: SimpleEvent<string>, field: string) => {
    setStatement((prevState) => ({
      ...prevState,
      [field]:
        field === 'weight'
          ? CatalogService.getCorrectedWeight(+event.target.value, prevState[field])
          : event.target.value,
    }));
  };

  const onIndicatorsUpdate = (
    event: SimpleEvent<OptionType | OptionType[]>,
    indicatorId: string,
    field: string,
  ) => {
    setStatement((prevState) => {
      const indicators = structuredClone(statement.indicatorInfo);
      indicators[indicatorId][field] = event.target.value;

      onInputRatingChanged(event.target.value as string, field, indicatorId);

      return { ...prevState, indicatorInfo: indicators };
    });
  };

  const updateIndicatorInfo = (
    field: string,
    targetIndicatorId: string,
    value: string | boolean,
  ) => {
    const updatedStatement = structuredClone(statement);

    if (updatedStatement?.type && isTypeHasRating(updatedStatement.type)) {
      Object.keys(updatedStatement?.indicatorInfo)?.forEach((indicatorId) => {
        if (targetIndicatorId !== indicatorId) {
          return;
        }

        if (field === DIMENSION_FIELDS.NAME) {
          updatedStatement.indicatorInfo[indicatorId].name = value;
          updatedStatement.indicatorInfo[indicatorId].description =
            prevStatementData?.[updatedStatement.type]?.indicatorInfo[indicatorId]?.description ||
            DEFAULT_INDICATORS[updatedStatement?.type][indicatorId].description;
        }
        if (field === DIMENSION_FIELDS.DESCRIPTION) {
          updatedStatement.indicatorInfo[indicatorId].description = value;
          updatedStatement.indicatorInfo[indicatorId].name =
            prevStatementData?.[updatedStatement.type]?.indicatorInfo[indicatorId]?.name ||
            DEFAULT_INDICATORS[updatedStatement?.type][indicatorId].name;
        }
      });
    }

    return updatedStatement;
  };

  const onInputRatingChanged = (
    value: string | boolean,
    field: string,
    targetIndicatorId: string,
  ) => {
    const updatedStatement = updateIndicatorInfo(field, targetIndicatorId, value);

    handleSetPrevStatement(updatedStatement);
  };

  const getInheritFiels = (inheritLevelsName: boolean, inheritLevelsDesc: boolean) => {
    const fields = [];

    if (inheritLevelsName !== undefined && inheritLevelsName) {
      fields.push(INHERIT_INDICATORS.LEVEL_NAME);
    }

    if (inheritLevelsDesc !== undefined && inheritLevelsDesc) {
      fields.push(INHERIT_INDICATORS.LEVEL_DESC);
    }

    return fields;
  };

  const getInheritFields = (updatedStatement: Statement, prevState: Statement) => {
    const parentIndicatorInfo = updatedStatement?.parentDimension?.indicatorInfo;

    let inheritLevelsName = prevState?.inheritLevelsName;
    let inheritLevelsDesc = prevState?.inheritLevelsDesc;

    if (parentIndicatorInfo) {
      Object.keys(updatedStatement?.indicatorInfo).forEach((indicatorId) => {
        const parentIndicatorName = parentIndicatorInfo[indicatorId]?.name;
        const parentIndicatorDescription = parentIndicatorInfo[indicatorId]?.description;

        if (parentIndicatorName) {
          inheritLevelsName = inheritLevelsName === undefined ? false : inheritLevelsName;
        }
        if (parentIndicatorDescription) {
          inheritLevelsDesc = inheritLevelsDesc === undefined ? false : inheritLevelsDesc;
        }
      });
    }
    return {
      inheritLevelsName,
      inheritLevelsDesc,
    };
  };

  const onSelectType = (newType: ANSWER_TYPES, isPrevStatementSave = true) => {
    const updatedStatement = structuredClone(statement);
    updatedStatement.type = newType;

    updatedStatement.indicatorInfo =
      prevStatementData?.[newType] &&
        !(
          isTypeHasRating(updatedStatement.type) &&
          (updatedStatement?.inheritLevelsName || updatedStatement?.inheritLevelsDesc)
        )
        ? prevStatementData?.[newType].indicatorInfo
        : prepareIndicators(
          newType,
          getInheritFiels(
            updatedStatement?.inheritLevelsName,
            updatedStatement?.inheritLevelsDesc,
          ),
        );

    const currentIndicatorChoices =
      updatedStatement?.indicatorChoices?.length && statement?.type === updatedStatement.type
        ? updatedStatement.indicatorChoices
        : [];

    updatedStatement.indicatorChoices = prevStatementData?.[newType]
      ? prevStatementData?.[newType].indicatorChoices
      : currentIndicatorChoices;

    setStatement((prevState) => {
      const { inheritLevelsName, inheritLevelsDesc } = getInheritFields(
        updatedStatement,
        prevState,
      );
      if (statement?.type && isPrevStatementSave) {
        updatePrevStatement(structuredClone(statement));
      }

      return {
        ...updatedStatement,
        inheritLevelsDesc,
        inheritLevelsName,
      };
    });
  };

  const setData = <T,>(data: T, fieldName: string) =>
    setStatement((prevState) => ({ ...prevState, [fieldName]: data }));

  const hasIndicatorInfoError = useMemo(() => {
    for (const key in statement.indicatorInfo) {
      if (!statement.indicatorInfo[key].name?.trim()) {
        return true;
      }
    }
    return false;
  }, [statement]);

  const hasChoicesError = () => {
    if (
      Array.isArray(statement?.indicatorChoices) &&
      !ScaleService.isRadioGroup(statement.type) &&
      !ScaleService.isMultiGroup(statement.type)
    ) {
      return;
    }

    return (
      isChoicesDuplicates(statement.indicatorChoices) || isChoicesEmpty(statement.indicatorChoices)
    );
  };

  const hasWeightError = useMemo(() => !statement.weight || statement.weight <= 0, [statement]);

  const isMetricStatementError = () =>
    ScaleService.isMetric(statement.type) &&
    (!statement?.metricName || isMetricBaseCheckError(statement.indicatorChoices));

  const isStatementHasError = () =>
    !statement?.type ||
    !statement?.description?.trim() ||
    isMetricStatementError() ||
    hasIndicatorInfoError ||
    hasWeightError ||
    hasChoicesError();

  const createStatementObject = (rateStatement: boolean) => {
    delete statement?.parentDimension;

    if (ScaleService.isMultiGroup(statement.type) || ScaleService.isOpenEnded(statement.type)) {
      return rateStatement
        ? { ...statement, rated: rateStatement }
        : { ...statement, rated: rateStatement, indicatorInfo: {} };
    }
    return statement;
  };

  const saveStatement = (event: MouseEvent<HTMLElement>) => {
    if (isStatementHasError()) {
      setShowError(true);
    } else {
      const statementWithTags = {
        ...createStatementObject(rateStatementActive),
        tags: collectStatementTags(statementTaxonomies, taxonomies),
      };

      const isRuleReset = isRulesAffected(initialStatement, statement);

      onAccept(trimStatementAnswers(statementWithTags as Statement), documentIds, isRuleReset);
      onClose(event);
    }
  };

  const handleIndicatorData = (indicatorId: string, field: string, data: boolean | string) => {
    setStatement((prevState) => {
      onInputRatingChanged(data, field, indicatorId);
      return {
        ...prevState,
        indicatorInfo: {
          ...prevState.indicatorInfo,
          [indicatorId]: {
            ...prevState.indicatorInfo[indicatorId],
            [field]: data,
          },
        },
      };
    });
  };

  const resetIndicatorDescription = (indicatorId: string) => {
    setStatement((prevState) => {
      onInputRatingChanged('', DIMENSION_FIELDS.DESCRIPTION, indicatorId);
      return {
        ...prevState,
        indicatorInfo: {
          ...prevState.indicatorInfo,
          [indicatorId]: {
            ...prevState.indicatorInfo[indicatorId],
            description: null,
            isEditMode: false,
          },
        },
      };
    });
  };

  const setStatementTags = (values: string[], categoryId: string) => {
    setStatementTaxonomies((prevState) => ({
      ...prevState,
      [categoryId]: values,
    }));
  };

  const onRateStatementActiveChange = (indicatorType: Partial<IndicatorInfo>) => {
    if (
      !statement.rated &&
      !JSService.getObjectLength(statement.indicatorInfo) &&
      !rateStatementActive
    ) {
      setStatement({
        ...statement,
        indicatorInfo: indicatorType as IndicatorInfo,
      });
    }

    setRateStatementActive(!rateStatementActive);
  };

  const tagIds = (taxonomyTags: Tag[]): string[] =>
    taxonomyTags.reduce((acc, curr) => [...acc, curr.id, ...tagIds(curr.tags)], [] as string[]);

  const getDocument = (res: StatementDocument[]): StatementDocument =>
    res && res.length ? res[0] : ({} as StatementDocument);

  const setStatementDocuments = (
    res: StatementDocument[],
    callbacks: { failed: () => void; success: () => void },
  ) => {
    const document = getDocument(res);
    NotificationService.showSuccess(MESSAGE.SUCCESS.DOCUMENT_ADDED);
    setData([...(statement.statementDocuments || []), document], 'statementDocuments');
    callbacks.success();
  };

  const attachDocuments = (
    files: AttachedDocument[],
    callbacks: { failed: () => void; success: () => void },
  ) => {
    SpinnerService.showSpinner('Please wait, the file is being uploaded.');

    if (!statement.id) {
      Promise.all(
        files.map((document) =>
          StatementDocumentAPI.uploadNoEntity({
            resource,
            document: document.file,
            comment: document.comment,
          }),
        ),
      )
        .then((res) => {
          setStatementDocuments(res, callbacks);
          setDocumentIds([...documentIds, getDocument(res).id]);
          if (onDocumentAttached) {
            onDocumentAttached(getDocument(res).id);
          }
        })
        .catch((error) => {
          ReviewService.showError(error);
          callbacks.failed();
        })
        .finally(() => {
          SpinnerService.hideSpinner();
        });
    } else {
      Promise.all(
        files.map((document) =>
          StatementDocumentAPI.uploadStatementDocument({
            resource: resource === RESOURCE_TYPES.REVIEW_STATEMENT ? 'review' : 'catalog',
            statementId: statement.id,
            document: document.file,
            comment: document.comment,
          }),
        ),
      )
        .then((res) => {
          setStatementDocuments(res, callbacks);
          if (onDocumentAttached) {
            onDocumentAttached(getDocument(res).id);
          }
        })
        .catch((error) => {
          ReviewService.showError(error);
          callbacks.failed();
        })
        .finally(() => {
          SpinnerService.hideSpinner();
        });
    }
  };

  const downloadDocument = (document: StatementDocument) => {
    SpinnerService.showSpinner();

    if (catalogDocIds.includes(document.id)) {
      downloadStatementDocument(document, RESOURCE_TYPES.CATALOG_STATEMENT);
    } else {
      downloadStatementDocument(document, resource);
    }
  };

  const handleDocumentDelete = (documentId: string) => {
    const updatedDocuments = statement.statementDocuments?.filter((item) => item.id !== documentId);
    setData(updatedDocuments, 'statementDocuments');
    onDocumentRemoved &&
      onDocumentRemoved(documentId, {
        ...statement,
        statementDocuments: updatedDocuments,
      });
  };

  const deleteDocument = (documentId: string) => {
    SpinnerService.showSpinner();

    if (catalogDocIds.includes(documentId)) {
      handleDocumentDelete(documentId);
    } else {
      StatementDocumentAPI.delete(resource, documentId)
        .then(() => {
          handleDocumentDelete(documentId);
        })
        .catch(ReviewService.showError)
        .finally(SpinnerService.hideSpinner);
    }
  };

  const handleDocumentEdit = (documentId: string, filename: string, comment: string) => {
    const newStatementDocuments = statement.statementDocuments ?? [];
    const documentIndex = newStatementDocuments.findIndex((item) => item.id === documentId);

    newStatementDocuments[documentIndex] = {
      ...newStatementDocuments[documentIndex],
      name: filename,
      comment,
    };

    setData(newStatementDocuments, 'statementDocuments');
  };

  const editProperties = (filename: string, comment: string, documentId: string) => {
    SpinnerService.showSpinner();

    if (catalogDocIds.includes(documentId)) {
      handleDocumentEdit(documentId, filename, comment);
    } else {
      StatementDocumentAPI.edit(resource, documentId, filename, comment)
        .then(() => {
          handleDocumentEdit(documentId, filename, comment);
        })
        .catch(ReviewService.showError)
        .finally(SpinnerService.hideSpinner);
    }
  };

  const getRatingTemplate = () =>
    Object.keys(statement.indicatorInfo)
      .sort((a, b) => SCORE_INDICATOR.RATING[a] - SCORE_INDICATOR.RATING[b])
      .map((indicatorId) => {
        const score = SCORE_INDICATOR.RATING[indicatorId];
        return (
          <div
            css={statementModalRatingGroup}
            key={`rating-input-indicator-${indicatorId}`}
          >
            <div css={[indicatorScore, ratingIndicatorScore]}>
              <Score
                score={`${score}`}
                style={{
                  ...SCORE_SCALE.RATING[`${score}`]?.style,
                  height: '36px',
                  width: '36px',
                }}
              />
            </div>
            <div css={statementModalIndicatorInput} >
              <Tooltip
                content={INHERIT_TOOLTIP_MESSAGE}
                placement="top"
                disabled={!statement.inheritLevelsName}
              >
                <div css={inputName}>
                  <Input
                    flexible
                    type="text"
                    value={statement.indicatorInfo?.[indicatorId]?.name || ''}
                    placeholder="Enter level name"
                    onChange={(event) =>
                      onIndicatorsUpdate(event, indicatorId, DIMENSION_FIELDS.NAME)
                    }
                    maxLength={NAME_MAX_LENGTH}
                    style={{ marginBottom: 0 }}
                    errors={{
                      levelName: SPECIFY_LEVEL_NAME_MESSAGE,
                    }}
                    errorMessages={{
                      levelName: SPECIFY_LEVEL_NAME_MESSAGE,
                    }}
                    disabled={statement.inheritLevelsName}
                    touched={!statement.indicatorInfo?.[indicatorId]?.name?.trim()}
                  />
                </div>
              </Tooltip>
              <ModalIndicatorDescription
                statement={statement}
                indicatorId={indicatorId}
                handleIndicatorData={handleIndicatorData}
                resetIndicatorDescription={resetIndicatorDescription}
                isDescriptionDisabled={statement.inheritLevelsDesc}
              />
            </div>
          </div>
        );
      });

  const updateInheritance = (eventName: string, value: boolean) => {
    setStatement((prevState) => {
      const checkedFields = [];

      if (eventName === INHERIT_INDICATORS.LEVEL_NAME) {
        value && checkedFields.push(INHERIT_INDICATORS.LEVEL_NAME);
        prevState.inheritLevelsDesc && checkedFields.push(INHERIT_INDICATORS.LEVEL_DESC);
      }
      if (eventName === INHERIT_INDICATORS.LEVEL_DESC) {
        value && checkedFields.push(INHERIT_INDICATORS.LEVEL_DESC);
        prevState.inheritLevelsName && checkedFields.push(INHERIT_INDICATORS.LEVEL_NAME);
      }

      const indicatorInfo = prepareIndicators(prevState.type, checkedFields);

      return {
        ...prevState,
        [eventName]: value,
        indicatorInfo,
      };
    });
  };

  const handleCommentRequiredCheckbox = () => {
    setStatement((prevState) => ({
        ...prevState,
        commentRequired: !prevState.commentRequired,
      }));
  };

  const levelNameAndDescriptionTemplate = () => (
    <div css={[modalInput, modalGrayWrapper, generalInputField]}>
      <label css={modalText} style={{ marginLeft: 57 }}>
        Level name and descriptions
      </label>
      <div css={generalInputField}>
        {!!JSService.getObjectLength(statement.indicatorInfo) && getRatingTemplate()}
      </div>
    </div>
  );

  const inheritCheckboxSection = () =>
    isInheritOptionVisible() && (
      <div style={{ paddingBottom: '0px' }} css={[modalGrayWrapper]}>
        <InheritCheckBoxes
          onChangeInheritance={updateInheritance}
          inheritDesc={statement.inheritLevelsDesc}
          inheritName={statement.inheritLevelsName}
        />
      </div>
    );

  const getSelectedOption = () => types.find((item) => item.id === statement.type);

  const handleOpenSelect = () => {
    setTimeout(() => {
      document.body.style.overflow = 'auto';
    }, 0)
  };

  const body = statement ? (
    <div css={[modalBody, statementModalBody]}>
      <div css={[leftSide, taxonomies?.length > 0 && leftSideBorder]}>
        <div css={[modalInput, generalInputField, doubleField]}>
          <div css={selectTypeField}>
            <MaterialSelect
              label="Answer Type"
              placeholder="Select Type"
              value={getSelectedOption()}
              options={types}
              onChange={(event) => onSelectType(event.target.value as ANSWER_TYPES)}
              errorMessages={() => MESSAGE.FAILURE.STATEMENT_TYPE}
              isTouched={showError && !statement.type}
              onOpen={() => handleOpenSelect()}
              disabled={
                isReviewStructure && !!JSService.getObjectLength(statement.expertScores || {})
              }
            />
          </div>
          <div css={statementWeightField}>
            <Input
              css={modalInputSmall}
              errorMessages={{ weight: MESSAGE.FAILURE.WEIGHT }}
              errors={{ weight: MESSAGE.FAILURE.WEIGHT }}
              label="Weight"
              maxLength={MAX_WEIGHT_LENGTH}
              onChange={(event) => onDataChange(event, 'weight')}
              touched={showError && hasWeightError}
              type="text"
              value={statement.weight.toString()}
            />
            <div css={[modalInfo, statementWeightDescription]}>{CATALOG_WEIGHT_INFO.STATEMENT}</div>
          </div>
        </div>

        {ScaleService.isMetric(statement.type) && (
          <div css={[modalInput, generalInputField, doubleField]}>
            <div css={[metricNameInputStyle]}>
              <Input
                flexible
                type="text"
                placeholder="Enter metric name"
                label="Metric Name"
                value={statement.metricName || ''}
                onChange={(event) => onDataChange(event, 'metricName')}
                disabled={isDisabled}
                errors={{
                  metricName: !statement.metricName?.trim() && MESSAGE.FAILURE.MODAL_FIELD_EMPTY,
                }}
                errorMessages={{
                  metricName: (!statement.metricName?.trim() &&
                    MESSAGE.FAILURE.MODAL_FIELD_EMPTY) as string,
                }}
                touched={showError && !statement.metricName?.trim()}
              />
            </div>

            <Input
              css={unitOfMeasureInputStyle}
              label="Unit of Measure"
              placeholder="Enter unit of measure"
              maxLength={METRIC_MEASURE_MAX_LENGTH}
              onChange={(event) => onDataChange(event, 'unitOfMeasure')}
              type="text"
              value={statement.unitOfMeasure?.toString() as string}
            />
          </div>
        )}

        <div css={[modalInput, generalInputField]}>
          <label css={modalText}>Statement Text</label>
          {/* @ts-ignore */}
          <TextArea
            height={96}
            maxLength={NOTE_MAX_LENGTH}
            placeholder="Enter Statement..."
            value={statement.description || ''}
            onChange={(event) => onDataChange(event, DIMENSION_FIELDS.DESCRIPTION)}
            disabled={isDisabled}
            errors={{ description: 'Statement text must be specified' }}
            errorMessages={{
              description: 'Statement text must be specified',
            }}
            touched={showError && !statement.description?.trim()}
          />
          <Tooltip
            content='The "Comment" field is required during the assessment process for the answerer to provide details about the selected option.'
            placement="top"
          >
            <div css={statementTextCheckbox}>
              <GenericCheckbox
                checked={statement?.commentRequired}
                onChange={handleCommentRequiredCheckbox}
                label={
                  <span style={{ whiteSpace: 'normal' }}>Comment required</span>
                }
              />
            </div>
          </Tooltip>
        </div>
        {ScaleService.isRadioGroup(statement.type) && (
          <div css={[modalInput, modalGrayWrapper, generalInputField]}>
            <div css={statementModalDescription}>
              {`Select a value from 1 to 5 for "Option" to include the statement
                in the final score.
                In case "N/a" is selected the statement is excluded from the
                calculation.`}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label css={modalText} style={{ marginLeft: 48 }}>
                Options
              </label>
              <label css={modalText} style={{ marginRight: 70 }}>
                Score
              </label>
            </div>
            <RadioChoices
              choices={statement.indicatorChoices}
              onChange={(indicatorChoices: IndicatorChoice) =>
                setData(indicatorChoices, 'indicatorChoices')
              }
              showError={showError}
            />
          </div>
        )}
        {ScaleService.isMultiGroup(statement.type) && (
          <>
            <div css={[modalInput, modalGrayWrapper, generalInputField]}>
              <div css={statementModalDescription}>
                Multi Select question has no predefined scoring. If the answer to the statement
                should be scored, please enable the toggle and add the scoring criteria (if
                necessary). Scoring could be done manually after selecting all the appropriate
                options during the assessment.
              </div>
              <div style={{ display: 'flex' }}>
                <label css={modalText} style={{ marginLeft: 48 }}>
                  Options
                </label>
              </div>
              <MultiChoices
                choices={statement.indicatorChoices}
                onChange={(indicatorChoices: IndicatorChoice) =>
                  setData(indicatorChoices, 'indicatorChoices')
                }
                showError={showError}
              />
            </div>
            <Switch
              label="Rate the statement after answering"
              checked={rateStatementActive}
              onChange={() =>
                onRateStatementActiveChange(
                  DEFAULT_INDICATORS.MULTI_GROUP as Partial<IndicatorInfo>,
                )
              }
              style={{ padding: 0 }}
            />

            {rateStatementActive && (
              <>
                {inheritCheckboxSection()}
                {levelNameAndDescriptionTemplate()}
              </>
            )}
          </>
        )}
        {ScaleService.isMetric(statement.type) && (
          <div css={[modalInput, modalGrayWrapper, generalInputField]}>
            <Metric
              indicatorChoices={statement.indicatorChoices}
              onChange={(indicatorChoices: IndicatorChoice[]) =>
                setData(indicatorChoices, 'indicatorChoices')
              }
              showError={showError}
            />
          </div>
        )}
        {ScaleService.isRating(statement.type) && (
          <>
            {inheritCheckboxSection()}
            {levelNameAndDescriptionTemplate()}
          </>
        )}
        {ScaleService.isBinary(statement.type) && (
          <div css={[modalInput, modalGrayWrapper, generalInputField]}>
            <div css={[generalInputField, statementModalYesNoGroup]}>
              {JSService.getObjectLength(statement.indicatorInfo) &&
                Object.keys(statement.indicatorInfo)
                  .sort((a, b) => b.localeCompare(a))
                  .map((indicatorId) => {
                    const score = SCORE_INDICATOR.BINARY[indicatorId];
                    return (
                      <div
                        css={statementModalYesNoRow}
                        key={`yes-no-input-indicator-${indicatorId}`}
                      >
                        <div css={[indicatorScore, commonIndicatorScore]}>
                          <Score
                            score={JSService.capitalize(`${score}`.toLowerCase())}
                            style={{
                              ...SCORE_SCALE.BINARY[`${score}`]?.style,
                              height: '36px',
                              width: '36px',
                              fontSize: '14px',
                            }}
                          />
                        </div>
                        <div css={yesNoFieldRightSide}>
                          <div>
                            <Select
                              label="Score"
                              placeholder="Select Score"
                              value={statement.indicatorInfo[indicatorId].mappedValue}
                              options={[1, 2, 3, 4, 5]}
                              onChange={(event) =>
                                onIndicatorsUpdate(event, indicatorId, 'mappedValue')
                              }
                              errors={{
                                score: 'Please enter a value from 1 to 5',
                              }}
                              errorMessages={{
                                score: 'Please enter a value from 1 to 5',
                              }}
                              touched={!statement.indicatorInfo[indicatorId].mappedValue}
                              css={statementModalYesNoScoreSelect}
                            />
                          </div>
                          <ModalIndicatorDescription
                            statement={statement}
                            indicatorId={indicatorId}
                            handleIndicatorData={handleIndicatorData}
                            resetIndicatorDescription={resetIndicatorDescription}
                          />
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        )}
        {ScaleService.isOpenEnded(statement.type) && (
          <>
            <div
              css={[modalInput, modalGrayWrapper, generalInputField]}
              style={{ background: 'none' }}
            >
              <div css={statementModalDescription}>
                An open question has no predefined answer options and scoring. Answering is done in
                an open-text format in the "answer field" during the assessment. If the answer to
                the statement should be scored, please enable the toggle and add the scoring
                criteria (if necessary). Scoring could be done manually during the assessment.
              </div>
            </div>
            <Switch
              label="Rate the statement after answering"
              checked={rateStatementActive}
              onChange={() => onRateStatementActiveChange(DEFAULT_INDICATORS.OPEN_ENDED)}
              style={{ padding: 0 }}
            />
            {rateStatementActive && (
              <>
                {inheritCheckboxSection()}
                {levelNameAndDescriptionTemplate()}
              </>
            )}
          </>
        )}
        <div css={[modalInput, generalInputField]}>
          <label css={modalText}>Supporting Info</label>
          <SupportingInfo
            supportingQuestions={statement.supportingQuestions}
            isModalType
            onChange={(supportingQuestions: SupportingQuestion[]) =>
              setData(supportingQuestions, 'supportingQuestions')
            }
            attachDocuments={attachDocuments}
            downloadDocument={downloadDocument}
            deleteDocument={deleteDocument}
            editProperties={editProperties}
            documents={statement.statementDocuments}
            isDisabled={isDisabled}
            isDocumentsDisabled={isDocumentsDisabled}
          />
        </div>
      </div>
      {taxonomies?.length > 0 && (
        <div css={rightSideOfModal}>
          <p css={modalText}>Taxonomies:</p>
          {taxonomies?.map((taxonomy) => (
            <div css={taxonomiesListWrapper} key={taxonomy.id}>
              <p css={taxonomiesListRootItemName}>{taxonomy.name}</p>
              <Tree
                css={taxonomiesList}
                onChange={(values) => setStatementTags(values, taxonomy.id)}
                treeData={adaptTags(taxonomy.tags).filter((e) => e.children)}
                value={statementTaxonomies[taxonomy.id] || []}
                defaultExpandedKeys={tagIds(taxonomy.tags || [])}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  ) : null;

  return (
    <ModalDialog
      hidePadding
      config={{
        body,
        disableSave: isDisabled,
        // @ts-ignore //
        handleCancel: (event: MouseEvent<HTMLElement, MouseEvent>) => onClose(event, true),
        handleConfirm: saveStatement as () => void,
        title,
      }}
      container={getRootTarget}
      fullWidth
      isShow={show}
      css={statementModalWrapper(!!taxonomies?.length)}
    />
  );
};

export default StatementModal;
