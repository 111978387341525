/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { RATING_SCORE_INDICATOR } from '@lib/common.constants';
import { CatalogDimension } from '@root/src/types/catalog.types';
import { Dimension, DimensionList } from '@root/src/types/dimension.types';

export const isDimensionsHaveDataToShow = (dimensions: DimensionList | Dimension[] | CatalogDimension[]): boolean =>
  Object.keys(dimensions || {}).filter((dimensionKey) => {
    const hasScore =
      dimensions[dimensionKey].expertAvgScore >= RATING_SCORE_INDICATOR.OPPORTUNISTIC;
    const hasIndicatorInfo = Object.keys(dimensions[dimensionKey].indicatorInfo).length > 0;

    return hasScore && hasIndicatorInfo;
  }).length > 0;

export const insertTextBeforeArticle = (initialText: string, newText: string): string => {
  const articleIndex = initialText.indexOf('<article');

  if (articleIndex !== -1) {
    const beforeArticle = initialText.substring(0, articleIndex);
    const afterArticle = initialText.substring(articleIndex);

    return beforeArticle + newText + afterArticle;
  }

  return initialText + newText;
};

export const removeDefaultText = (targetText: string, defaultText: string) => {
  if (!targetText) {
    return '';
  }
  const regExp = new RegExp(defaultText, 'g');

  return targetText.replace(regExp, '');
}
