/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useMemo, useState, Dispatch, MouseEvent, SetStateAction } from 'react';
import { Button, ExtendedStepper, StepProps, Tooltip, ButtonType } from '@perf/ui-components';
import { ButtonVariant } from '@perf/ui-components/dist/Button/Button.types';
import THUMB_UP from '@app/img/svg/thumb_up.svg';
import StatementsSurvey from '@components/common/statements-survey/statements-survey.component';
import DummyResult from '@components/element/dummy-result/dummy-result.component';
import { APP_REVIEW_ROLES, INDICATORS_TYPES, VISIBLE_CONDITION } from '@lib/common.constants';
import { JSService } from '@services/js.service';
import { ModalService } from '@services/modal.service';
import { ScaleService } from '@services/scale.service';
import { AssessmentStatement, CatalogDimension, IVoter, ScoreDetail } from '@root/src/types/catalog.types';
import { IPermissionRoles, User } from '@root/src/types/user.types';
import { useAssessmentStore } from '@root/src/store/use.assessment.store';
import { setProgress, updateReviewDimensionsAction, resetDependedStatements, unFinishVoter, setCurrentVoterAction } from '@root/src/store/assessment.actions';
import { AnonymsAssessmentActions, useAnonymsAssessmentStore } from '@root/src/store/use.anonyms-assessment.store';
import { setAnonymsAssessmentState, setAnonymsProgress } from '@root/src/store/anonyms-assessment.actions';
import { modifyDimensionData } from '@components/views/assessment-view/assessment-view.utils';
import { convertDimensionObjectToArray } from '@root/src/utils/dimensions.utils';
import { checkCondition, getVoterIndex, resetUserScores, sortDimensionsByPageAndOrder } from '@components/common/dynamic-survey/dynamic-survey.utils';
import { TemplatePage } from '@app/types/template.types';

interface IDynamicSurveyProps {
  dimensions?: CatalogDimension[] | TemplatePage[];
  controllableStep?: number;
  previewMode?: boolean;
  user: User;
  voter: IVoter | User;
  roles: APP_REVIEW_ROLES[] | IPermissionRoles[];
  anonymous?: boolean;
  activeDimensionId?: string;
  onUpdate?: (activeStepId: string) => void;
  onFinish: (event?: MouseEvent<HTMLElement>) => void;
  synchronizeStepsNum?: Dispatch<SetStateAction<number | null>>;
  synchronizeStep?: Dispatch<SetStateAction<number>>;
  onTabActivate?: () => void;
  isEditable: boolean;
  isDocumentsAvailable?: boolean;
  reviewId?: string;
  isFinished?: boolean;
  onSave?: () => void;
  isCompleted?: boolean;
  handleAssessmentTab?: (tabIndex: number) => void;
  isReviewDataPrepared?: boolean;
  isDynamicSurveyDataUpdated?: boolean;
  setIsDynamicSurveyDataUpdated?: Dispatch<SetStateAction<boolean>>;
}

const DynamicSurvey = ({
  dimensions: dimensionsProps,
  controllableStep,
  previewMode,
  user,
  voter: voterProps,
  roles,
  anonymous,
  activeDimensionId,
  onUpdate,
  onFinish,
  synchronizeStepsNum,
  synchronizeStep,
  onTabActivate,
  isEditable,
  isDocumentsAvailable,
  reviewId,
  isFinished,
  onSave,
  isCompleted,
  handleAssessmentTab,
  isReviewDataPrepared,
  isDynamicSurveyDataUpdated,
  setIsDynamicSurveyDataUpdated,
}: IDynamicSurveyProps) => {
  const [steps, setSteps] = useState<{ dimensionId: string, label: string }[]>([]);
  const [statements, setStatements] = useState<AssessmentStatement[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [prevControllableStep, setPrevControllableStep] = useState<number | undefined>();
  const [voter, setVoter] = useState<IVoter>(voterProps as IVoter);

  const { currentVoter } = useAssessmentStore.getState().assessmentState;

  useEffect(() => {
    setVoter(voterProps as IVoter || currentVoter as IVoter);
  }
  , [voterProps, anonymous, currentVoter]);

  useEffect(() => {
    if (previewMode) {
      synchronizeStepsNum?.(filteredEmptySteps.length);
    }
  }
  , [steps, statements]);

  const handleProgress = (ignoreSave?: boolean, updatedStatements?: AssessmentStatement[], dimensionId?: string) => anonymous ? setAnonymsProgress(ignoreSave, updatedStatements, dimensionId) : setProgress(ignoreSave, updatedStatements, dimensionId, true, () => setIsDynamicSurveyDataUpdated?.(true));

  const getTargetDimensions = () => {
    if (anonymous) {
      const { review } = useAnonymsAssessmentStore.getState().assessmentState;

      return review?.dimensions;
    }
    const { review } = useAssessmentStore.getState().assessmentState;

    return previewMode ? dimensionsProps as CatalogDimension[]: review?.dimensions;
  };

  const componentOnInit = (isActiveStepReset?: boolean) => {
    const dimensions = getTargetDimensions();

    if (dimensions?.length) {
      prepareSteps(dimensions);

      if (previewMode) {
        handleActiveStep(controllableStep || 0);
      }
      handleActiveStep(activeStep || 0);

      if (dimensions) {
        prepareStatements(dimensions);
        selectActiveStep(dimensions, !!isActiveStepReset);
      }
    }
  };

  const prepareStatements = (currentSteps: CatalogDimension[]) => {
    const preparedStatements = specialGetStatements(currentSteps);

    initStatementsVisibility(preparedStatements);
  };

  useEffect(() => {
    if (previewMode || isReviewDataPrepared || anonymous || isDynamicSurveyDataUpdated) {
      componentOnInit();
      setIsDynamicSurveyDataUpdated?.(false);
    }
  }, [isReviewDataPrepared, dimensionsProps, isDynamicSurveyDataUpdated]);

  useEffect(() => {
    if (controllableStep !== prevControllableStep) {
      setPrevControllableStep(controllableStep);
      handleActiveStep(controllableStep || 0);
    }
  }, [controllableStep]);

  const selectActiveStep = (targetSteps: CatalogDimension[], isActiveStepReset: boolean) => {
    if (isActiveStepReset) {
      return handleActiveStep(0);
    }

    let updatedActiveStep = activeStep || 0;
    if (!previewMode) {
      targetSteps.forEach((step, index) => {
        if (step.id === activeDimensionId) {
          updatedActiveStep = index;
        }
      });
    }
    handleActiveStep(updatedActiveStep);
  };

  const filteredEmptySteps = useMemo(() => {
    const dimensions = getTargetDimensions();

    return steps.filter(({ dimensionId }) => {
      const targetDimension = dimensions.find(dimension => dimension.id === dimensionId) as CatalogDimension;

      return targetDimension.statements.find((statement) => {
        if (previewMode) {
          const targetStatement = statements.find(({ id }) => id === statement.id);
          return targetStatement?.visible;
        }
        return statement.visible;
      });
    });
  }, [steps, statements]);

  const specialGetStatements = (currentSteps: CatalogDimension[]): AssessmentStatement[] => currentSteps.reduce((targetStatements, step) => {
      const statementsWithDefaultVisibility = saveDefaultVisibility(step.statements);
      const statementWithParent = setStatementsParent(statementsWithDefaultVisibility, step.id, step.name);
      return [...targetStatements, ...statementWithParent];
    }, [] as AssessmentStatement[]);

  const setStatementsParent = (targetStatements:  AssessmentStatement[], parentId: string, parentName: string) => targetStatements.map((statement) => {
      statement.parent = {
        id: parentId,
        name: parentName,
      };
      return statement;
    });

  const saveDefaultVisibility = (statements: AssessmentStatement[]) => statements.map((statement) => {
      statement.defaultVisible = JSService.getObjectCopy(statement).visible;
      return statement;
    });

  const prepareSteps = (initialData: CatalogDimension[]) => {
    const targetData = previewMode ? convertDimensionObjectToArray(initialData) : initialData;

    const sortedData = targetData
      .sort((dimensionA, dimensionB) => dimensionA.order - dimensionB.order)
      .map((dimension) => ({
          dimensionId: dimension.id,
          label: dimension.name,
        }));

    setSteps(sortedData);
  };

  const initStatementsVisibility = (preparedStatements: AssessmentStatement[]) => {
    handleVisibility(preparedStatements, true);
  };

  const handleVisibility = (statementsList: AssessmentStatement[], initialCheck?: boolean) => {
    const dimensions = getTargetDimensions();
    const sortedDimensions = sortDimensionsByPageAndOrder(dimensions, statementsList);
    const checkStatements = (targetStatements: AssessmentStatement[]) => sortedDimensions.map((statement) => {
      if (statement.visibleIf) {
        // OR condition case
        if (statement.visibleIf.OR?.length > 0) {
          return handleOrConditionCase(statement, targetStatements, initialCheck);
        }

        // AND condition case
        if (statement.visibleIf.AND?.length > 0) {
          return handleAndConditionCase(statement, targetStatements, initialCheck);
        }
        return statement;
      }
      statement.visible = true;

      return statement;
    });

    // for some cases visibility of statements depends on next statement (e.g. second statement depends on third)
    // in this case visibility of second statement should be updated after third statement
    // that why we need to call updateStatementData again
    const checkedStatements = checkStatements(statementsList);
    const dobleCheckedStatements = checkStatements(checkedStatements);

    updateStatementData(dobleCheckedStatements, initialCheck);

    return dobleCheckedStatements;
  };

  const updateStatementData = (targetStatements: AssessmentStatement[], initialCheck?: boolean) => {
    const dimensions = getTargetDimensions();

    const targetDimensions = structuredClone(dimensions);
    const updatedStatements = initialCheck ? targetStatements : resetDependedStatements(targetStatements as AssessmentStatement[]);
    const updatedDimensions = targetDimensions.reduce((acc, dimension) => modifyDimensionData(
        acc,
        updatedStatements,
        dimension.id,
      ), targetDimensions);

    if (anonymous) {
      const { review } = useAnonymsAssessmentStore.getState().assessmentState;

      setAnonymsAssessmentState({
        review: {
          ...review,
          dimensions: updatedDimensions,
        }
      }, AnonymsAssessmentActions.setReview);
    }
    if (!previewMode) {
      updateReviewDimensionsAction(updatedDimensions);
    }

    setStatements(updatedStatements);
  };

  const handleOrConditionCase = (
    statement: AssessmentStatement,
    preparedStatements: AssessmentStatement[],
    initialCheck?: boolean,
  ) => {
    const allOfAndConditionSuccess = checkCondition(VISIBLE_CONDITION.AND, statement, preparedStatements, user.id, voter.id,initialCheck);
    const oneOfOrConditionSuccess = checkCondition(VISIBLE_CONDITION.OR, statement, preparedStatements, user.id, voter.id, initialCheck);

    const isVisible = allOfAndConditionSuccess || oneOfOrConditionSuccess;

    statement.visible = isVisible;
    if (!isVisible && !initialCheck) {
      return resetUserScores(statement, voter.id, user.id, true);
    };
    return statement;
  };

  const handleAndConditionCase = (
    statement: AssessmentStatement,
    statementsList: AssessmentStatement[],
    initialCheck?: boolean,
) => {
    const allConditions = [...statement.visibleIf.AND];

    if (allConditions?.length) {
      const allAndConditionSuccess = checkCondition(
        VISIBLE_CONDITION.AND,
        statement,
        statementsList,
        user.id,
        voter.id,
        initialCheck,
      );

      statement.visible = allAndConditionSuccess;

      if (!allAndConditionSuccess && !initialCheck) {
        return resetUserScores(statement, voter.id, user.id, true);
      }
    }
    return statement;
  };

  const isCompletedSurvey = (votedSurvey: IVoter) => !!votedSurvey?.finishDate;

  const handleBack = () => {
    if (previewMode) {
      synchronizeStep?.(activeStep - 1);
    } else if (anonymous) {
      handleActiveStep(activeStep - 1);
    } else {
      handleActiveStep(activeStep - 1);
      const dimensionId = getActiveStepId(activeStep - 1);
      onUpdate?.(dimensionId);
    }
  };

  const getActiveStepId = (currentActiveStep: number) => steps[currentActiveStep].dimensionId;

  const handleReset = () => {
    if (previewMode || anonymous) {
      handleActiveStep(0);
      resetStatements(false);
    } else {
      handleActiveStep(0);
      resetStatements(true);
    }
  };

  const resetStatements = (isProgressUpdate: boolean) => {
    const updatedStatements = statements.map((statement) => {
      statement.visible = statement.defaultVisible as boolean;
      return resetUserScores(statement, voter.id, user.id, true);
    });

    updateStatementData(updatedStatements);
    handleProgress(true);

    if (isProgressUpdate) {
      onUpdate?.(getActiveStepId(0));
      componentOnInit(true);
    }
  };

  const isNextDisabled = () => {
    if (previewMode) {
      return activeStep === getStepsLength() - 1;
    }
    return (
      activeStep === getStepsLength() - 1 ||
      statementsAreNotCompleted(activeStep)
    );
  };

  const handleNext = () => {
    if (previewMode) {
      synchronizeStep?.(activeStep + 1);
    } else if (anonymous) {
      handleActiveStep(activeStep + 1);
    } else {
      handleActiveStep(activeStep + 1);
      onUpdate?.(getActiveStepId(activeStep + 1));
    }
  };

  const isFinishDisabled = () =>
    activeStep !== getStepsLength() - 1 ||
    statementsAreNotCompleted(activeStep) ||
    isFinished;

  const getStepsLength = () => filteredEmptySteps.length;

  const statementsAreNotCompleted = (activeStep: number) => {
    const dimensions = getTargetDimensions();

    let hasError = false;
    const targetStep = filteredEmptySteps[activeStep] ? filteredEmptySteps[activeStep] : filteredEmptySteps[0];

    const isReviewExpert = isUserReviewExpert(roles as APP_REVIEW_ROLES[]);
    const userId = user?.id;
    const statements = dimensions?.find((dimension) => dimension.id === targetStep.dimensionId)?.statements;

    if (!statements?.length) return hasError;

    statements.forEach((statement) => {
      const statementUserScores = statement.userScores?.[userId];
      const hasValue = hasScoreValue(statement, statementUserScores);

      if (anonymous) {
        const multiGroupHasError = isAnonymousMultiGroupError(
          statement,
          statementUserScores,
          isReviewExpert,
        );

        hasError = !hasError
          ? checkErrorForAnonymous(statement, hasValue, multiGroupHasError)
          : hasError;
      } else {
        const voterIndex = getVoterIndex(statement.userScores, user.id, voter.id);
        const voterUserScore = voterIndex > -1 && (statementUserScores[voterIndex] || {});
        const multiGroupVoterError = isMultiGroupError(
          statement,
          voterUserScore as ScoreDetail,
          isReviewExpert,
        );

        hasError = !hasError
          ? checkErrorForNonAnonymous(statement, voterUserScore as ScoreDetail, multiGroupVoterError)
          : hasError;
      }
    });

    return hasError;
  };

  const handleActiveStep = (activeStep: number) => {
    setActiveStep(activeStep);

    if (previewMode) {
      synchronizeStep?.(activeStep)
    }
  };

  const handleFinish = () => {
    openAcceptModal(() => onFinish());
  };

  const openAcceptModal = (callback: () => void) => {
    ModalService.openAcceptModal({
      title: 'Finish survey',
      description: 'Are you sure you want to finish the Survey?',
      onAccept: callback,
    });
  };

  const hasScoreValue = (statement: AssessmentStatement, userScores: ScoreDetail[]) =>
    ScaleService.isOpenEnded(statement.type)
      ? userScores?.[0]?.userChoices?.length
      : userScores?.[0]?.userScore;

  const isAnonymousMultiGroupError = (statement: AssessmentStatement, userScores: ScoreDetail[], isReviewExpert: boolean) =>
    statement.rated && isReviewExpert
      ? !userScores?.[0]?.userScore
      : !userScores?.[0]?.userChoices?.length;

  const checkErrorForAnonymous = (statement: AssessmentStatement, hasValue: number | INDICATORS_TYPES | null, multiGroupHasError: boolean) =>
    statement.visible &&
    (ScaleService.isMultiGroup(statement.type) ? multiGroupHasError : !hasValue);

  const isMultiGroupError = (statement: AssessmentStatement, voterUserScore: ScoreDetail, isReviewExpert: boolean) =>
    statement.rated && isReviewExpert
      ? !voterUserScore.userScore
      : !voterUserScore.userChoices?.length;

  const checkErrorForNonAnonymous = (statement: AssessmentStatement, voterUserScore: ScoreDetail, multiGroupHasError: boolean) =>
    statement.visible &&
    (!ScaleService.isMultiGroup(statement.type) && !ScaleService.isOpenEnded(statement.type)
      ? !voterUserScore.userScore
      : multiGroupHasError);

  const isUserReviewExpert = (roles: APP_REVIEW_ROLES[]) => (roles || []).includes(APP_REVIEW_ROLES.REVIEW_EXPERT);

  const onChangeAnswers = () => {
    const updatedVoter = {
      ...voter,
      finishDate: '',
      isVoted: false,
    };
    const callback = () => {
      handleAssessmentTab?.(0);
    }
    unFinishVoter(reviewId as string, updatedVoter.id, updatedVoter as IVoter, false, callback);
    setCurrentVoterAction(updatedVoter as IVoter);
  };

  const dummyResultButtons = useMemo(() => [
    ...(!isFinished ? [{
      name: 'Change Answers',
      type: 'secondary' as ButtonType,
      variant: 'outlined' as ButtonVariant,
      onClick: onChangeAnswers,
    }] : []),
    {
      name: 'View Results',
      type: 'primary' as ButtonType,
      onClick: onTabActivate,
    },
  ], [onTabActivate, isFinished]);

  return (
    <>
      {!!JSService.getObjectLength(steps) && (
        <div className="survey-stepper">
          <ExtendedStepper
            key={`steps-${filteredEmptySteps.length}`}
            disabled={!previewMode}
            linear={false}
            activeStep={activeStep}
            steps={filteredEmptySteps as StepProps[]}
            className="survey-stepper--header"
            onStepChange={(step) => handleActiveStep(step)}
          />
          <section className="survey-stepper--body">
            {isCompletedSurvey(voter as IVoter) ? (
              <DummyResult
                src={THUMB_UP}
                message="All Done!"
                description="Thank you, you've completed the entire Survey."
                buttons={dummyResultButtons}
                big
              />
            ) : (
              <>
                {(!!JSService.getObjectLength(statements)) && (
                  <StatementsSurvey
                    initialStatements={statements}
                    dimensionId={
                      filteredEmptySteps?.[activeStep]?.dimensionId
                    }
                    user={user}
                    voter={voter}
                    onProgressSet={(ignoreSave, updatedStatements, dimensionId) => handleProgress(ignoreSave, updatedStatements, dimensionId)}
                    onVisibleChange={handleVisibility}
                    isEditable={isEditable}
                    isSurveyType
                    parentId={filteredEmptySteps[activeStep]?.dimensionId}
                    previewMode={previewMode}
                    roles={roles as APP_REVIEW_ROLES[]}
                    anonymous={!!anonymous}
                    isDocumentsAvailable={!!isDocumentsAvailable}
                    reviewId={reviewId || ''}
                    isFinished={!!isFinished}
                    setIsDimensionWasUpdated={setIsDynamicSurveyDataUpdated}
                  />
                )}
                {!previewMode && (
                  <section className="survey-stepper--controls">
                    <div className="survey-stepper--controls-left">
                      <Button
                        disabled={activeStep === 0}
                        variant="outlined"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                      <Button variant="outlined" disabled={isFinished} onClick={handleReset}>
                        Clear Answers
                      </Button>
                    </div>
                    <div className="survey-stepper--controls-right">
                      <Button disabled={isNextDisabled()} onClick={handleNext}>
                        Next
                      </Button>
                      {!anonymous && (
                        <Button
                          type="success"
                          disabled={isFinishDisabled()}
                          onClick={handleFinish}
                        >
                          Finish
                        </Button>
                      )}
                      {anonymous && (
                        <Tooltip
                          content="To finish the survey you must answer all the questions"
                          placement="top"
                          disabled={isCompleted}
                        >
                          <div style={{ display: 'inline-flex' }}>
                            <Button
                              type="success"
                              disabled={!isCompleted}
                              onClick={onSave as () => void}
                            >
                              Send
                            </Button>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </section>
                )}
              </>
            )}
          </section>
        </div>
      )}
    </>
  );
};

export default DynamicSurvey;
